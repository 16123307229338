.banner-section .our_story_box {
  position: absolute;
  left: 10%;
  bottom: 10%;
  max-width: 600px;
  background: rgba(21, 121, 191, 0.8);
  padding: 25px 30px;
  z-index: 9;
}

@media only screen and (max-width: 1500px) {
  .banner-section .our_story_box {
    max-width: 400px;
    left: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-section .our_story_box {
    max-width: 100%;
    left: 0;
    position: static;
  }
}

.banner-section .our_story_box .inner h2 {
  font-size: 48px;
  color: #fff;
  margin-bottom: 20px;
  font-weight: 700;
}

@media only screen and (max-width: 1500px) {
  .banner-section .our_story_box .inner h2 {
    font-size: 36px;
  }
}

.banner-section .our_story_box .inner p {
  font-family: "DINRegularAlternate", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1500px) {
  .banner-section .our_story_box .inner p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1920px) {
  .banner-section .our_story_box .inner .btn {
    width: 200px;
  }
}

@media only screen and (max-width: 1500px) {
  .banner-section .our_story_box .inner .btn {
    width: 200px;
  }
}

/* .banner-section .our_story_box .inner .btn span:hover {
  color: #000;
} */

.banner-section .our_story_box .inner .btn span:hover:before,
.banner-section .our_story_box .inner .btn span:hover:after {
  background: #000;
  width: 100%;
}

.banner-section .our_story_box .alcove_start {
  margin-top: 50px;
}

@media only screen and (max-width: 1500px) {
  .banner-section .our_story_box .alcove_start {
    margin-top: 35px;
  }
}

.banner-section .our_story_box .alcove_start p {
  font-family: "DINRegularAlternate", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 20px;
}

.banner-section .our_story_box .alcove_start p span {
  font-weight: 600;
  font-size: 20px;
}

.banner-section .our_story_box .alcove_start .year {
  width: 100%;
  display: flex;
}

.banner-section .our_story_box .alcove_start .year a {
  font-family: "DINRegularAlternate";
  font-size: 42px;
  color: #fff;
  margin-bottom: 20px;
  display: inline-block;
  vertical-align: middle;
  width: 30%;
}

.banner-section .our_story_box .alcove_start .year a.active {
  font-weight: bold;
}

@media only screen and (max-width: 1500px) {
  .banner-section .our_story_box .alcove_start .year a {
    font-size: 32px;
  }
}

.banner-section img {
  -webkit-animation: myMove 10s 1 ease-in-out;
  -moz-animation: myMove 10s 1 ease-in-out;
  -o-animation: myMove 10s 1 ease-in-out;
  -ms-animation: myMove 10s 1 ease-in-out;
  animation: myMove 10s 1 ease-in-out;
  -webkit-animation-fill-mode: forwards;
  transition: ease 8s all;
}

.banner-section img.mob-banner {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .banner-section img.mob-banner {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .banner-section img.mob-banner {
    animation: unset;
  }
}

.banner-section img.desk-banner {
  display: block;
}

@media only screen and (max-width: 1023px) {
  .banner-section img.desk-banner {
    display: none;
  }
}

.banner-section .item img {
  width: 100%;
}

.banner-section .wrap-slick1 {
  position: relative;
}

.banner-section .wrap-slick1 .prev-slick1,
.banner-section .wrap-slick1 .next-slick1,
.banner-section .wrap-slick1 .arrow-slick3 {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  font-size: 30px;
  color: #fff;
  outline: 0;
  border: 0;
  background: transparent;
  font-size: 90px;
}

.banner-section .wrap-slick1 .prev-slick1 {
  left: 20px;
}

.banner-section .wrap-slick1 .next-slick1 {
  right: 20px;
}

.banner-section .wrap-slick1-dots {
  width: 100%;
  padding: 20px 0;
  text-align: center;
}

.banner-section .wrap-slick1-dots .slick1-dots {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}

.banner-section .wrap-slick1-dots .slick1-dots li {
  padding-left: 5px;
  padding-right: 5px;
}

.banner-section .wrap-slick1-dots .slick1-dots li button {
  display: block;
  color: transparent;
  background-color: #bdcad1;
  width: 15px;
  height: 15px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  outline: none;
  border: 0;
}

.banner-section .wrap-slick1-dots .slick1-dots li.slick-active button {
  background-color: #05b59d;
}

.banner-section .banner-title {
  font-family: "DINRegularAlternate-ExtraBold";
  text-align: left;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 8%;
  color: #fff;
  z-index: 9;
  transition: all linear 0.5s;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  opacity: 0;
  margin-top: -30px;
  backface-visibility: hidden;
}

@media only screen and (max-width: 1300px) {
  .banner-section .banner-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .banner-section .banner-title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .banner-section .banner-title {
    font-size: 36px;
    top: 80%;
  }
}

.banner-section .banner-title.active {
  opacity: 1;
}

.banner-section .banner-text {
  color: #ffffff;
  font-size: 50px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  right: 5%;
}

.banner-section.about_bann {
  padding-top: 60px;
}

#footer {
  padding: 40px 0 0 0;
}

#footer .footer-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 90px;
}

#footer .footer-wrapper ul {
  text-align: center;
}

#footer .footer-wrapper ul li {
  margin-bottom: 12px;
}

#footer .footer-wrapper ul li a {
  color: #666666;
  font-size: 14px;
  text-transform: capitalize;
}

#footer .footer-wrapper ul li a:hover {
  color: #05b59d;
}

#footer .footer-wrapper ul li .social-footer-list a {
  display: inline-block;
  margin: 0 6px;
  font-size: 18px;
}

.gallery-section {
  text-align: center;
}

.gallery-section .gallery-slider .gallery-item {
  padding: 10px;
  display: block;
}

.gallery-section .gallery-slider .gallery-item img {
  width: 100%;
  display: block;
}

.gallery-section .gallery-slider .owl-prev {
  position: absolute;
  top: 50%;
  left: -3%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gallery-section .gallery-slider .owl-next {
  position: absolute;
  top: 50%;
  right: -3%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gallery-section .theme-btn {
  margin-top: 40px;
}

.gallery-section-project {
  padding: 50px 0 50px 0;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .gallery-section-project {
    padding: 0 0 35px 0;
  }
}

.gallery-section-project .gallery_slide .gallery-item {
  padding: 10px;
  display: block;
}

.gallery-section-project .gallery_slide .gallery-item img {
  width: 100%;
  display: block;
}

.gallery-section-project .gallery_slide .gallery-item h3 {
  color: #114159;
  text-transform: uppercase;
  font-size: 20px;
  margin: 18px 0;
  display: block;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .gallery-section-project .gallery_slide .gallery-item h3 {
    font-size: 18px;
  }
}

.gallery-section-project .gallery_slide .owl-prev {
  position: absolute;
  top: 50%;
  left: -3%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .gallery-section-project .gallery_slide .owl-prev {
    width: 15px;
  }
}

.gallery-section-project .gallery_slide .owl-next {
  position: absolute;
  top: 50%;
  right: -3%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .gallery-section-project .gallery_slide .owl-next {
    width: 15px;
  }
}

.gallery-section-project .theme-btn {
  margin-top: 10px;
}

.tabs li.active a {
  background: #05b59d;
  color: #ffffff;
}

.submit_query {
  -webkit-border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 28%;
  z-index: 99999;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  width: 41px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .submit_query {
    top: 28%;
  }
}

.flip-container {
  height: 350px;
}
.overlay {
  background: rgba(0, 0, 0, 0.85) none repeat scroll 0 0;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  display: none;
}

.pop_box {
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 300px;
  width: 90%;
  background: #006ebb;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: none;
  border-radius: 3px 3px 3px 3px;
  padding: 3px;
}

.pop_box .pop_box_inner {
  background: #fff;
}

.pop_box .pop_box_inner .logo_pop {
  text-align: center;
  width: 100%;
  display: block;
  padding: 25px 0 0px;
}

.pop_box .pop_box_inner .form_box {
  padding: 1px 20px 20px;
}

.pop_box .pop_box_inner .enqury_bx .form_row {
  margin-top: 15px;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
}

.pop_box .pop_box_inner .enqury_bx .form_row label {
  width: 35%;
}

.pop_box .pop_box_inner .enqury_bx .form_row input[type="text"] {
  width: 100%;
  border: none;
  border-bottom: 1px solid #006ebb;
  height: 30px;
  padding: 5px;
}

.pop_box .pop_box_inner .enqury_bx .form_row textarea {
  width: 100%;
  height: 70px;
  border: 1px solid #006ebb;
  resize: none;
  padding-top: 3px;
  padding-left: 5px;
}

.pop_box .pop_box_inner .enqury_bx .form_row input[type="Submit"] {
  text-align: center;
  background: #006ebb;
  border: none;
  padding: 5px 20px;
  margin: 0 auto;
  color: #fff;
}

.pop_box .close_popup {
  position: absolute;
  right: -10px;
  top: -10px;
}

.bread_crum {
  padding: 30px 0 0;
}

@media only screen and (max-width: 1400px) {
  .bread_crum {
    padding: 20px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .bread_crum {
    padding: 10px 0 0;
  }
}

.bread_crum .bread_box ul li {
  display: inline-block;
  padding: 0 1px;
}

.bread_crum .bread_box ul li a {
  color: #006ebb;
}

.bread_crum .bread_box ul li.active {
  color: #006ebb;
}

.title {
  color: black;
  text-transform: uppercase;
  font-size: 42px;
  margin-bottom: 25px;
  display: block;
  text-align: center;
  line-height: 40px;
}

@media only screen and (max-width: 1500px) {
  .title {
    font-size: 30px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1279px) {
  .title {
    font-size: 28px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 479px) {
  .title {
    font-size: 26px;
    margin-bottom: 0;
  }
}

.title.text-left {
  text-align: left;
}

@media only screen and (max-width: 1023px) {
  .title.text-left {
    text-align: center;
  }
}

.upcoming_projects {
  padding: 60px 0 20px 0px;
}

@media only screen and (max-width: 1279px) {
  .upcoming_projects {
    padding: 35px 0 0 0;
  }
}

@media only screen and (max-width: 639px) {
  .upcoming_projects {
    padding: 25px 0 0 0;
  }
}

.upcoming_projects .projects .upcoming_para p {
  font-family: "DINRegularAlternate", sans-serif;
  text-align: center;
  line-height: 24px;
}

.project_data_section {
  padding: 60px 0 20px 0px;
}

@media only screen and (max-width: 1279px) {
  .project_data_section {
    padding: 35px 0 20px 0;
  }
}

@media only screen and (max-width: 639px) {
  .project_data_section {
    padding: 25px 0 20px 0;
  }
}

.project_data_section .the_slider {
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .project_data_section .the_slider {
    border: solid 1px #e1e1e1;
  }
}

.project_data_section .the_slider .line01 {
  height: 1px;
  background: #e1e1e1;
  right: 0;
  width: 0%;
  top: 0;
  position: absolute;
}

.project_data_section .the_slider .line01.showme {
  width: 100%;
}

@media only screen and (max-width: 1023px) {
  .project_data_section .the_slider .line01 {
    display: none;
  }
}

.project_data_section .the_slider .line02 {
  width: 1px;
  background: #e1e1e1;
  left: 0;
  top: 0;
  position: absolute;
  height: 0%;
}

.project_data_section .the_slider .line02.showme {
  height: 100%;
}

@media only screen and (max-width: 1023px) {
  .project_data_section .the_slider .line02 {
    display: none;
  }
}

.project_data_section .the_slider .line03 {
  height: 1px;
  background: #e1e1e1;
  left: 0;
  width: 100%;
  bottom: 0;
  position: absolute;
}

.project_data_section .the_slider .line03.showme {
  width: 100%;
}

@media only screen and (max-width: 1023px) {
  .project_data_section .the_slider .line03 {
    display: none;
  }
}

.project_data_section .the_slider .line04 {
  height: 100%;
  background: #e1e1e1;
  right: 0;
  width: 1px;
  bottom: 0;
  position: absolute;
}

.project_data_section .the_slider .line04.showme {
  height: 100%;
}

@media only screen and (max-width: 1023px) {
  .project_data_section .the_slider .line04 {
    display: none;
  }
}

.project_data_section .the_slider .iteams {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1279px) {
  .project_data_section .the_slider .iteams {
    align-items: flex-start;
  }
}

@media only screen and (max-width: 1023px) {
  .project_data_section .the_slider .iteams {
    display: block;
    padding: 10px;
    padding-bottom: 45px;
  }
}

.project_data_section .the_slider .iteams .img70 {
  width: 60%;
}

@media only screen and (max-width: 1279px) {
  .project_data_section .the_slider .iteams .img70 {
    width: 40%;
  }
}

@media only screen and (max-width: 1023px) {
  .project_data_section .the_slider .iteams .img70 {
    width: 50%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 479px) {
  .project_data_section .the_slider .iteams .img70 {
    width: 80%;
    margin: 0 auto;
  }
}

.project_data_section .the_slider .iteams .content {
  width: 40%;
  padding: 0px 20px 0px 25px;
}

@media only screen and (max-width: 1279px) {
  .project_data_section .the_slider .iteams .content {
    width: 60%;
    padding: 0 15px 0 15px;
  }
}

@media only screen and (max-width: 1023px) {
  .project_data_section .the_slider .iteams .content {
    width: 100%;
    padding: 0;
    margin-top: 15px;
    text-align: center;
  }
}

.project_data_section .the_slider .iteams .content p {
  line-height: 24px;
}

@media only screen and (max-width: 1279px) {
  .project_data_section .the_slider .iteams .content p {
    line-height: 20px;
    font-size: 13px;
  }
}

.project_data_section .the_slider .owl-controls {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

@media only screen and (max-width: 1279px) {
  .project_data_section .the_slider .owl-controls {
    bottom: 5px;
    right: 10px;
  }
}

@media only screen and (max-width: 1023px) {
  .project_data_section .the_slider .owl-controls {
    text-align: center;
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .project_data_section .the_slider .owl-controls .owl-nav {
    display: inline-block;
  }
}

.project_data_section .the_slider .owl-controls .owl-prev {
  float: left;
}

@media only screen and (max-width: 1400px) {
  .project_data_section .the_slider .owl-controls .owl-prev img {
    width: 70%;
  }
}

.project_data_section .the_slider .owl-controls .owl-next {
  float: right;
  padding-left: 10px;
}

@media only screen and (max-width: 1400px) {
  .project_data_section .the_slider .owl-controls .owl-next {
    padding-left: 1px;
  }

  .project_data_section .the_slider .owl-controls .owl-next img {
    width: 70%;
  }
}

.project_data_section.gloria_section .owl-controls {
  position: absolute;
  left: 15px;
  bottom: 15px;
}

@media only screen and (max-width: 1279px) {
  .project_data_section.gloria_section .owl-controls {
    bottom: 5px;
    left: 10px;
  }
}

@media only screen and (max-width: 1023px) {
  .project_data_section.gloria_section .owl-controls {
    text-align: center;
  }
}

@media only screen and (max-width: 1023px) {
  .project_data_section.gloria_section .owl-controls .owl-nav {
    display: inline-block;
  }
}

.project_data_section.gloria_section .owl-controls .owl-prev {
  float: left;
}

@media only screen and (max-width: 1400px) {
  .project_data_section.gloria_section .owl-controls .owl-prev img {
    width: 70%;
  }
}

.project_data_section.gloria_section .owl-controls .owl-next {
  float: left;
  padding-left: 10px;
}

@media only screen and (max-width: 1400px) {
  .project_data_section.gloria_section .owl-controls .owl-next {
    padding-left: 1px;
  }

  .project_data_section.gloria_section .owl-controls .owl-next img {
    width: 70%;
  }
}

.find_property {
  margin-top: 0px;
  background: #d7d7d7;
}

.find_property .bread_crum {
  padding: 30px 0 0;
}

@media only screen and (max-width: 1400px) {
  .find_property .bread_crum {
    padding: 20px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .find_property .bread_crum {
    padding: 10px 0 0;
  }
}

.find_property .bread_crum .bread_crum .bread_box ul li {
  display: inline-block;
  padding: 0 1px;
}

.find_property .bread_crum .bread_crum .bread_box ul li a {
  color: #000;
}

.find_property .bread_crum .bread_crum .bread_box ul li.active {
  color: #000;
}

@media only screen and (max-width: 1279px) {
  .find_property {
    padding: 25px 0;
  }
}

.find_property .property_search {
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .find_property .property_search {
    display: block;
  }
}

.find_property .property_search .property {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .find_property .property_search .property {
    width: 100%;
  }
}

.find_property .property_search .property .selection_box h4 {
  margin-bottom: 15px;
  font-family: "DINRegularAlternate";
  font-family: "DINRegularAlternate", sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
}

.find_property .property_search .property .selection_box .drop_down {
  width: 25%;
  margin-right: -4px;
  display: inline-block;
  vertical-align: middle;
}

@media only screen and (max-width: 639px) {
  .find_property .property_search .property .selection_box .drop_down {
    width: 100%;
    margin: 5px 0;
  }
}

.find_property .property_search .property .selection_box .drop_down select {
  width: 90%;
  padding: 6px 5px;
  border: 0;
}

@media only screen and (max-width: 639px) {
  .find_property .property_search .property .selection_box .drop_down select {
    width: 100%;
  }
}

.find_property .property_search .property .selection_box .drop_down .sub-btn {
  background: #006ebb;
  width: 90%;
  text-align: center;
  height: 34px;
  line-height: 34px;
  border: none;
  color: #fff;
}

@media only screen and (max-width: 639px) {
  .find_property .property_search .property .selection_box .drop_down .sub-btn {
    width: 100%;
  }
}

.find_property
  .property_search
  .property
  .selection_box
  .drop_down
  .select2-container--default
  .select2-selection--single {
  border: 0px solid #aaa !important;
  border-radius: 0px !important;
}

.find_property
  .property_search
  .property
  .selection_box
  .drop_down
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 34px;
}

.find_property
  .property_search
  .property
  .selection_box
  .drop_down
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}

.find_property
  .property_search
  .property
  .selection_box
  .drop_down
  .select2-container
  .select2-selection--single {
  height: 34px;
}

.find_property
  .property_search
  .property
  .selection_box
  .drop_down
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  background: url(/assets/select-dropdown.png) no-repeat;
  width: 27px;
  height: 15px;
  margin-top: 10px;
  padding: 14px 15px 9px;
}

@media only screen and (max-width: 1023px) {
  .find_property
    .property_search
    .property
    .selection_box
    .drop_down
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    padding: 0;
    width: 20px;
    background-size: 100%;
    right: 5px;
  }
}

.find_property .property_search .Search_bx {
  width: 30%;
  width: 30%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

@media only screen and (max-width: 1023px) {
  .find_property .property_search .Search_bx {
    width: 50%;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 639px) {
  .find_property .property_search .Search_bx {
    width: 100%;
    margin: 5px 0;
  }
}

.find_property .property_search .Search_bx h4 {
  margin-bottom: 15px;
}

@media only screen and (max-width: 1023px) {
  .find_property .property_search .Search_bx h4 {
    margin-bottom: 10px;
  }
}

.find_property .property_search .Search_bx select {
  width: 90%;
  padding: 6px 5px;
}

@media only screen and (max-width: 639px) {
  .find_property .property_search .Search_bx select {
    width: 100%;
  }
}

.find_property
  .property_search
  .Search_bx
  .select2-container--default
  .select2-selection--single {
  border: 0px solid #aaa !important;
  border-radius: 0px !important;
}

.find_property
  .property_search
  .Search_bx
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 34px;
}

.find_property
  .property_search
  .Search_bx
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}

.find_property
  .property_search
  .Search_bx
  .select2-container
  .select2-selection--single {
  height: 34px;
}

.find_property
  .property_search
  .Search_bx
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  background: url(/assets/select-dropdown.png) no-repeat;
  width: 27px;
  height: 15px;
  margin-top: 10px;
  padding: 14px 15px 9px;
}

@media only screen and (max-width: 1023px) {
  .find_property
    .property_search
    .Search_bx
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    padding: 0;
    width: 20px;
    background-size: 100%;
    right: 5px;
  }
}

.floor_walk {
  padding: 60px 0 20px 0px;
}

@media only screen and (max-width: 1279px) {
  .floor_walk {
    padding: 35px 0 20px 0;
  }
}

@media only screen and (max-width: 639px) {
  .floor_walk {
    padding: 25px 0 0 0;
  }
}

.floor_walk .mix {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1023px) {
  .floor_walk .mix {
    align-items: flex-start;
  }
}

@media only screen and (max-width: 1023px) and (max-width: 639px) {
  .floor_walk .mix {
    display: block;
  }
}

.floor_walk .mix .floor_plan_dv {
  width: 48%;
}

@media only screen and (max-width: 639px) {
  .floor_walk .mix .floor_plan_dv {
    width: 100%;
    margin: 0 auto;
  }
}

.floor_walk .mix .floor_plan_dv .floor_panel {
  position: relative;
  text-align: center;
}

@media only screen and (max-width: 639px) {
  .floor_walk .mix .floor_plan_dv .floor_panel {
    position: static;
  }
}

.floor_walk .mix .floor_plan_dv .floor_panel .line01 {
  height: 1px;
  background: #e1e1e1;
  right: 0;
  width: 0%;
  top: 0;
  position: absolute;
}

.floor_walk .mix .floor_plan_dv .floor_panel .line01.showme {
  width: 100%;
}

.floor_walk .mix .floor_plan_dv .floor_panel .line02 {
  width: 1px;
  background: #e1e1e1;
  left: 0;
  top: 0;
  position: absolute;
  height: 0%;
}

.floor_walk .mix .floor_plan_dv .floor_panel .line02.showme {
  height: 100%;
}

.floor_walk .mix .floor_plan_dv .floor_panel .line03 {
  height: 1px;
  background: #e1e1e1;
  left: 0;
  width: 100%;
  bottom: 0;
  position: absolute;
}

.floor_walk .mix .floor_plan_dv .floor_panel .line03.showme {
  width: 100%;
}

.floor_walk .mix .floor_plan_dv .floor_panel .line04 {
  height: 100%;
  background: #e1e1e1;
  right: 0;
  width: 1px;
  bottom: 0;
  position: absolute;
}

.floor_walk .mix .floor_plan_dv .floor_panel .line04.showme {
  height: 100%;
}

.floor_walk .mix .walk_through_dv {
  width: 48%;
  position: relative;
}

@media only screen and (max-width: 639px) {
  .floor_walk .mix .walk_through_dv {
    width: 100%;
  }

  .floor_walk .mix .walk_through_dv.slide-to-left {
    left: 0px;
    margin-top: 25px;
  }
}

.floor_walk .mix .walk_through_dv .walk_panel {
  position: relative;
  text-align: center;
}

.floor_walk .mix .walk_through_dv .walk_panel iframe {
  height: 549px;
}

@media only screen and (max-width: 1023px) {
  .floor_walk .mix .walk_through_dv .walk_panel iframe {
    height: 457px;
  }
}

@media only screen and (max-width: 767px) {
  .floor_walk .mix .walk_through_dv .walk_panel iframe {
    height: 382px;
  }
}

.floor_walk .mix .walk_through_dv .walk_panel .line01 {
  height: 1px;
  background: #e1e1e1;
  right: 0;
  width: 0%;
  top: 0;
  position: absolute;
}

.floor_walk .mix .walk_through_dv .walk_panel .line01.showme {
  width: 100%;
}

.floor_walk .mix .walk_through_dv .walk_panel .line02 {
  width: 1px;
  background: #e1e1e1;
  left: 0;
  top: 0;
  position: absolute;
  height: 0%;
}

.floor_walk .mix .walk_through_dv .walk_panel .line02.showme {
  height: 100%;
}

.floor_walk .mix .walk_through_dv .walk_panel .line03 {
  height: 1px;
  background: #e1e1e1;
  left: 0;
  width: 100%;
  bottom: 0;
  position: absolute;
}

.floor_walk .mix .walk_through_dv .walk_panel .line03.showme {
  width: 100%;
}

.floor_walk .mix .walk_through_dv .walk_panel .line04 {
  height: 100%;
  background: #e1e1e1;
  right: 0;
  width: 1px;
  bottom: 0;
  position: absolute;
}

.floor_walk .mix .walk_through_dv .walk_panel .line04.showme {
  height: 100%;
}

.contact_section {
  padding: 60px 0 20px 0px;
  position: relative;
  display: table;
}

.contact_section .box_con {
  display: table-cell;
  vertical-align: middle;
  background: rgba(225, 225, 225, 0.8);
  width: 50%;
  height: 100%;
}

.contact_section .box_con .con_titles {
  margin: 0 auto;
  max-width: 100%;
  padding: 40px 2px 0px 160px;
}

.contact_section .box_con .con_titles h4 {
  color: black;
  text-transform: uppercase;
  font-size: 36px;
  margin-bottom: 10px;
  display: block;
  text-align: center;
  line-height: 40px;
  font-family: "DINRegularAlternate";
  font-weight: 800;
}

.contact_section .box_con .con_titles p {
  line-height: 24px;
}

.contact_section .box_con .middle {
  margin: 0 auto;
  max-width: 57%;
  padding: 0px 15px 0px;
}

.contact_section .box_con .middle .form_dv {
  margin-top: 15px;
}

.contact_section .box_con .middle .form_dv .form_row {
  margin-top: 15px;
}

.contact_section .box_con .middle .form_dv .form_row input[type="text"] {
  width: 100%;
  border: none;
  border-bottom: 1px solid #666;
  height: 20px;
  padding: 5px;
  background: transparent;
}

.contact_section .box_con .middle .form_dv .form_row input[type="submit"] {
  width: 100%;
  border: none;
  border-bottom: 1px solid #666;
  border-top: 1px solid #666;
  text-transform: uppercase;
  height: 35px;
  padding: 5px;
  background: transparent;
}

.contact_section .box_con .middle .form_dv .form_row textarea {
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 1px solid #666;
  resize: none;
  padding-top: 3px;
  padding-left: 5px;
  background: transparent;
}

.contact_section .box_con .middle .form_dv .form_row .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.contact_section .box_con .middle .form_dv .form_row .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.contact_section
  .box_con
  .middle
  .form_dv
  .form_row
  .container
  input:checked
  ~ .checkmark {
  background-color: #666;
}

.contact_section
  .box_con
  .middle
  .form_dv
  .form_row
  .container
  input:checked
  ~ .checkmark:after {
  display: block;
}

.contact_section .box_con .middle .form_dv .form_row .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: solid 1px #666;
}

.contact_section .box_con .middle .form_dv .form_row .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 3px;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.contact_section .box_con .address_box {
  margin: 0 auto;
  max-width: 100%;
  padding: 25px 40px 30px 160px;
  line-height: 24px;
}

.contact_section .box_con .address_box p {
  margin-bottom: 10px;
}

.contact_section .box_con .address_box a {
  margin-bottom: 10px;
  color: #000;
}

.contact_section .contact_img_right {
  display: table-cell;
  vertical-align: middle;
  background: url(/assets/contact-img.jpg) no-repeat;
  background-position: center center;
  width: 100%;
  display: table-cell;
  vertical-align: middle;
}

.footer .wrapper {
  padding: 50px 0 20px 0;
  width: 100%;
}

@media only screen and (max-width: 1279px) {
  .footer .wrapper {
    padding-top: 25px;
  }
}

.footer .wrapper .links {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.footer .wrapper .links li {
  display: inline-block;
  padding: 0 10px;
  border-left: solid 1px #000;
}

@media only screen and (max-width: 1279px) {
  .footer .wrapper .links li {
    padding: 0 4px;
  }
}

@media only screen and (max-width: 1023px) {
  .footer .wrapper .links li {
    height: 25px;
    line-height: 25px;
    width: 19%;
    margin-top: 4px;
    border: solid 1px #000;
    vertical-align: top;
  }
}

@media only screen and (max-width: 767px) {
  .footer .wrapper .links li {
    width: 24%;
  }
}

@media only screen and (max-width: 639px) {
  .footer .wrapper .links li {
    width: 32%;
  }
}

@media only screen and (max-width: 479px) {
  .footer .wrapper .links li {
    width: 49%;
  }
}

.footer .wrapper .links li:first-child {
  border-left: 0;
}

@media only screen and (max-width: 1023px) {
  .footer .wrapper .links li:first-child {
    border-left: solid 1px #000;
  }
}

.footer .wrapper .links li a {
  text-align: center;
  color: #000;
  text-transform: capitalize;
}

@media only screen and (max-width: 1279px) {
  .footer .wrapper .links li a {
    font-size: 13px;
  }
}

.footer .wrapper .bottom_footer {
  margin: 0 auto;
  text-align: center;
}

.footer .wrapper .bottom_footer .footer_logo1 {
  margin-top: 25px;
  display: block;
}

.footer .wrapper .bottom_footer ul {
  margin-top: 15px;
}

.footer .wrapper .bottom_footer ul li {
  margin-bottom: 12px;
}

.footer .wrapper .bottom_footer ul li a {
  color: #666666;
  font-size: 14px;
  text-transform: capitalize;
}

.footer .wrapper .bottom_footer ul li a:hover {
  color: #ccc;
}

.footer .wrapper .bottom_footer ul li .social-footer-list a {
  display: inline-block;
  margin: 0 6px 15px;
  font-size: 18px;
}

.footer .wrapper .bottom_footer ul li p {
  margin-top: 15px;
}

.experience_section {
  padding: 40px 0 20px 0px;
}

@media only screen and (max-width: 1300px) {
  .experience_section {
    padding: 35px 0 0 0;
  }
}

.experience_section .exper_in h3 {
  color: black;
  text-transform: uppercase;
  font-size: 42px;
  margin-bottom: 10px;
  display: block;
  line-height: 40px;
  font-family: "DINRegularAlternate";
  font-weight: 800;
}

@media only screen and (max-width: 1400px) {
  .experience_section .exper_in h3 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 1300px) {
  .experience_section .exper_in h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .experience_section .exper_in h3 {
    font-size: 26px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .experience_section .exper_in h3 {
    font-size: 20px;
    line-height: 24px;
  }
}

.experience_section .exper_in p {
  font-family: "DINRegularAlternate", sans-serif;
  line-height: 24px;
  text-align: justify;
  margin-top: 20px;
}

@media only screen and (max-width: 1500px) {
  .experience_section .exper_in p {
    font-size: 14px;
  }
}

.experience_section .exper_in p ~ p {
  margin-top: 20px;
  font-size: 14px;
}

.mission_section,
.commitment_section {
  padding: 60px 0 20px 0px;
}

@media only screen and (max-width: 1300px) {
  .mission_section,
  .commitment_section {
    padding: 35px 0 0 0;
  }
}

.mission_section .mission_box,
.commitment_section .mission_box {
  position: relative;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .mission_section .mission_box,
  .commitment_section .mission_box {
    display: block;
  }
}

.mission_section .mission_box .line01,
.commitment_section .mission_box .line01 {
  height: 1px;
  background: #e1e1e1;
  right: 0;
  width: 0%;
  top: 0;
  position: absolute;
}

.mission_section .mission_box .line01.showme,
.commitment_section .mission_box .line01.showme {
  width: 100%;
}

.mission_section .mission_box .line02,
.commitment_section .mission_box .line02 {
  width: 1px;
  background: #e1e1e1;
  left: 0;
  top: 0;
  position: absolute;
  height: 0%;
}

.mission_section .mission_box .line02.showme,
.commitment_section .mission_box .line02.showme {
  height: 100%;
}

.mission_section .mission_box .line03,
.commitment_section .mission_box .line03 {
  height: 1px;
  background: #e1e1e1;
  left: 0;
  width: 100%;
  bottom: 0;
  position: absolute;
}

.mission_section .mission_box .line03.showme,
.commitment_section .mission_box .line03.showme {
  width: 100%;
}

.mission_section .mission_box .line04,
.commitment_section .mission_box .line04 {
  height: 100%;
  background: #e1e1e1;
  right: 0;
  width: 1px;
  bottom: 0;
  position: absolute;
}

.mission_section .mission_box .line04.showme,
.commitment_section .mission_box .line04.showme {
  height: 100%;
}

.mission_section .mission_box .left_50,
.commitment_section .mission_box .left_50 {
  width: 50%;
}

@media only screen and (max-width: 1023px) {
  .mission_section .mission_box .left_50,
  .commitment_section .mission_box .left_50 {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .mission_section .mission_box .left_50,
  .commitment_section .mission_box .left_50 {
    width: 100%;
  }
}

.mission_section .mission_box .right_50,
.commitment_section .mission_box .right_50 {
  width: 50%;
  padding: 0px 25px 0px 20px;
}

@media only screen and (max-width: 1023px) {
  .mission_section .mission_box .right_50,
  .commitment_section .mission_box .right_50 {
    width: 50%;
    padding: 0 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .mission_section .mission_box .right_50,
  .commitment_section .mission_box .right_50 {
    width: 100%;
    padding: 0;
  }
}

.mission_section .mission_box .right_50 p,
.commitment_section .mission_box .right_50 p {
  line-height: 24px;
}

@media only screen and (max-width: 1023px) {
  .mission_section .mission_box .right_50 p,
  .commitment_section .mission_box .right_50 p {
    line-height: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .mission_section .mission_box .right_50 p,
  .commitment_section .mission_box .right_50 p {
    text-align: justify;
  }
}

@media only screen and (max-width: 639px) {
  .mission_section .mission_box .right_50 p,
  .commitment_section .mission_box .right_50 p {
    padding: 20px;
  }
}

.alcove-video-section {
  padding: 40px 0 20px 0px;
}

.alcove-video-section .iframe-wrap {
  border: solid 1px #e1e1e1;
  padding: 10px;
  width: 100%;
  margin: 0 auto;
}

.alcove-video-section iframe {
  height: 300px;
  width: 100%;
}

@media only screen and (max-width: 639px) {
  .alcove-video-section iframe {
    height: 300px;
  }
}

.search-property {
  padding: 60px 0 20px 0px;
}

.search-property .search-project-box {
  border: solid 1px #eee;
}

.search-property .search-project-box .project-img img {
  width: 100%;
}

.search-property .search-project-box .search-project-dlt {
  padding: 10px 20px;
}

.search-property .search-project-box .search-project-dlt .heading04 {
  font-size: 24px;
  font-family: "DINRegularAlternate";
  font-family: "DINRegularAlternate", sans-serif;
  text-transform: uppercase;
  text-align: center;
  padding: 10px 0;
}

.search-property .search-project-box .search-project-dlt .heading04:hover {
  color: #000;
}

@media only screen and (max-width: 1023px) {
  .search-property .search-project-box .search-project-dlt .heading04 {
    font-size: 18px;
  }
}

.search-property .search-project-box .search-project-dlt .detail-list {
  margin-top: 5px;
  padding-left: 5px;
}

.search-property .search-project-box .search-project-dlt .detail-list li {
  padding: 5px 0;
}

.search-property .search-project-box .search-project-dlt .detail-list li span {
  display: inline-block;
  vertical-align: middle;
}

.search-property
  .search-project-box
  .search-project-dlt
  .detail-list
  li
  span:first-child {
  width: 154px;
  position: relative;
}

.search-property
  .search-project-box
  .search-project-dlt
  .detail-list
  li
  span:first-child:after {
  content: ":";
  position: absolute;
  right: 0;
}

@media only screen and (max-width: 479px) {
  .search-property
    .search-project-box
    .search-project-dlt
    .detail-list
    li
    span:first-child {
    width: 50%;
  }
}

.search-property
  .search-project-box
  .search-project-dlt
  .detail-list
  li
  span:last-child {
  width: -webkit-calc(100% - 154px);
  width: -moz-calc(100% - 154px);
  width: -ms-calc(100% - 154px);
  width: calc(100% - 154px);
  padding-left: 10px;
}

@media only screen and (max-width: 479px) {
  .search-property
    .search-project-box
    .search-project-dlt
    .detail-list
    li
    span:last-child {
    width: 50%;
  }
}

.search-property .search-project-box .search-project-dlt .view-project {
  display: block;
  width: 100%;
  background: #006ebb;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  margin-top: 25px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.search-property .search-project-slider,
.search-property .project-dlt-slider {
  padding: 0 50px;
}

@media only screen and (max-width: 479px) {
  .search-property .search-project-slider,
  .search-property .project-dlt-slider {
    padding: 0 20px;
  }
}

.search-property .search-project-slider .owl-prev,
.search-property .search-project-slider .owl-next,
.search-property .project-dlt-slider .owl-prev,
.search-property .project-dlt-slider .owl-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.search-property .search-project-slider .owl-prev,
.search-property .project-dlt-slider .owl-prev {
  left: 0;
}

@media only screen and (max-width: 479px) {
  .search-property .search-project-slider .owl-prev,
  .search-property .project-dlt-slider .owl-prev {
    left: -20px;
  }
}

.search-property .search-project-slider .owl-next,
.search-property .project-dlt-slider .owl-next {
  right: 0;
}

@media only screen and (max-width: 479px) {
  .search-property .search-project-slider .owl-next,
  .search-property .project-dlt-slider .owl-next {
    right: -20px;
  }
}

.search-property .project-dlt-slider {
  text-align: center;
}

.search-property .project-dlt-slider .heading04 {
  font-size: 30px;
  text-transform: uppercase;
  font-family: "DINRegularAlternate";
  font-family: "DINRegularAlternate", sans-serif;
}

.search-property .project-dlt-slider p {
  padding: 20px;
}

@media only screen and (max-width: 767px) {
  .search-property .project-dlt-slider p {
    text-align: justify;
    padding: 5px;
    line-height: 20px;
  }
}

.designation_section {
  padding: 60px 0 10px 0px;
  height: 90vh;
}

@media only screen and (max-width: 767px) {
  .designation_section {
    height: auto;
  }

  .designation_section .heading h3 {
    text-align: left;
    margin-bottom: 20px;
  }

  .designation_section .bb-custom-wrapper > nav {
    width: 75px;
    right: 0;
    top: -55px;
  }
}

@media only screen and (max-width: 359px) {
  .designation_section .heading h3 {
    margin-bottom: 50px;
    text-align: center;
  }

  .designation_section .bb-custom-wrapper > nav {
    top: -43px;
  }
}

.home-body {
  font-size: 14px;
  font-family: "DINRegularAlternate", sans-serif;
  color: #666666;
  overflow-x: hidden;
}

@media only screen and (max-width: 1500px) {
  .home-body {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1023px) {
  .home-body {
    font-size: 12px;
  }
}

.hideme {
  opacity: 0;
  position: relative;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -webkit-opacity: 0;
  -webkit-transition: ease 1s all;
  -moz-transition: ease 1s all;
  transition: ease 1s all;
  -webkit-perspective-origin: 50% 50%;
  -moz-perspective-origin: 50% 50%;
  -ms-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
}

.hideme.scale-box {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: ease 10s all;
  -moz-transition: ease 10s all;
  transition: ease 10s all;
  opacity: 1;
}

.scale-box.showme {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.showme {
  opacity: 1;
}

.delay1 {
  transition-delay: 0.5s;
}

.delay2 {
  transition-delay: 1s;
}

.delay3 {
  transition-delay: 1.5s;
}

.delay4 {
  transition-delay: 2s;
}

.delay5 {
  transition-delay: 2.5s;
}

.delay6 {
  transition-delay: 3s;
}

.delay7 {
  transition-delay: 3.5s;
}

.delay8 {
  transition-delay: 4s;
}

.delay9 {
  transition-delay: 4.5s;
}

.delay10 {
  transition-delay: 5s;
}

.section {
  margin-top: 100px;
}

@media only screen and (max-width: 1500px) {
  .section {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .section {
    margin-top: 25px;
  }
}

.main-wrapper {
  overflow-x: hidden;
}

.home-main-wrapper .banner-section {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .home-main-wrapper .banner-section {
    height: auto;
  }
}

.home-main-wrapper .banner-section .slider_new {
  overflow: hidden;
}

.home-main-wrapper .banner-section .slider_new .show-banner {
  transform: scale(1);
  transition: ease 10s all;
}

@media only screen and (max-width: 767px) {
  .home-main-wrapper .banner-section .slider_new .show-banner {
    height: 100vh;
    background: url(/assets/exterior-img2.jpg) no-repeat bottom center;
    background-size: cover;
  }

  .home-main-wrapper .banner-section .slider_new .show-banner img {
    display: none;
  }
}

.home-main-wrapper .banner-section .thefourty-slider {
  overflow: hidden;
}

.home-main-wrapper .banner-section .slider {
  position: absolute;
  display: none;
}

.home-main-wrapper .banner-section .slider .loader {
  position: absolute;
  width: 0;
  height: 7px;
  left: 0;
  bottom: 0;
  background: rgba(0, 110, 187, 0.9);
  left: 0;
}

@media only screen and (max-width: 1500px) {
  .home-main-wrapper .banner-section .slider .loader {
    height: 5px;
    -webkit-transition: ease 5s all;
    -moz-transition: ease 5s all;
  }
}

.home-main-wrapper .banner-section .slider .show-banner {
  transform: scale(1);
  transition: ease 10s all;
}

.home-main-wrapper .banner-section .slider.active .loader {
  width: 100%;
}

.home-main-wrapper .banner-section .slider.active .show-banner {
  transform: scale(1.1);
}

.home-main-wrapper .banner-section .banner-top-box {
  position: absolute;
  top: 34.77vh;
  left: 0px;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.home-main-wrapper .banner-section .banner-top-box .btn {
  margin-top: 45px;
}
.heading03 {
  font-size: 48px;
  font-family: "Montserrat-Thin";
  color: white;
  text-transform: uppercase;
  line-height: 36px;
}

@media only screen and (max-width: 1500px) {
  .heading03 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 1023px) {
  .heading03 {
    font-size: 26px;
    line-height: 23px;
  }
}
.home-main-wrapper .banner-section .banner-top-box .heading002 {
  letter-spacing: 5px;
  color: #ffffff;
  font-family: "DINRegularAlternate";
  font-weight: 800;
  text-transform: uppercase;
  line-height: 48px;
  display: block;
  padding: 5px;
  text-shadow: 2px 5px 3px #58b;
}

.home-main-wrapper .banner-section .banner-bottom-box {
  position: absolute;
  max-width: 1024px;
  width: 80%;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 5.5%;
}

@media only screen and (max-width: 1500px) {
  .home-main-wrapper .banner-section .banner-bottom-box {
    max-width: 900px;
  }
}

@media only screen and (max-width: 1279px) {
  .home-main-wrapper .banner-section .banner-bottom-box {
    max-width: 768px;
  }
}

.home-main-wrapper .banner-section .banner-bottom-box .project-link-box {
  width: -webkit-calc(33% - 10px);
  width: -moz-calc(33% - 10px);
  width: -ms-calc(33% - 10px);
  width: calc(33% - 10px);
  float: left;
  position: relative;
  padding-bottom: 15px;
  margin: 0 5px;
  padding: 20px 0;
  background: rgba(255, 255, 255, 0.9);
}

@media only screen and (max-width: 1500px) {
  .home-main-wrapper .banner-section .banner-bottom-box .project-link-box {
    padding: 15px 0;
  }
}

.home-main-wrapper .banner-section .banner-bottom-box .project-link-box a {
  text-align: center;
  display: block;
}

.home-main-wrapper .banner-section .banner-bottom-box .project-link-box span {
  color: #000;
  font-family: "DINRegularAlternate";
  font-weight: 800;
  text-transform: uppercase;
  line-height: 11px;
  display: block;
}

.home-main-wrapper .banner-section .banner-bottom-box .project-link-box p {
  font-size: 46px;
  font-family: "DINRegularAlternate";
  font-family: "DINRegularAlternate", sans-serif;
  color: #000;
  margin-top: 22px;
  line-height: 40px;
  font-weight: 600;
}

@media only screen and (max-width: 1500px) {
  .home-main-wrapper .banner-section .banner-bottom-box .project-link-box p {
    font-size: 36px;
    line-height: 32px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1500px) {
  .home-main-wrapper .banner-section .banner-bottom-box .project-link-box p {
    font-size: 30px;
    line-height: 25px;
    margin-top: 10px;
  }
}

.mob-banner {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .mob-banner {
    display: block;
  }
}

@media only screen and (max-width: 1023px) {
  .desk-banner {
    display: none;
  }
}

.welcome-section {
  text-align: center;
  margin-top: 40px;
}

.welcome-section .award-img {
  margin: 45px 0 65px;
}

.welcome-section p {
  margin-top: 15px;
}

.welcome-section .btn {
  color: #666666;
  margin-top: 20px;
}

.welcome-section .btn:before,
.welcome-section .btn:after {
  background: #666666;
}

.welcome-section .btn:hover {
  color: #006ebb;
}

.newkolkata-section {
  text-align: center;
}

.newkolkata-section .height-img {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.newkolkata-section .project-logo {
  text-align: center;
}

/* .newkolkata-section .project-logo .new-kolkata-logo {
  width: 16vw;
} */

@media only screen and (max-width: 767px) {
  .newkolkata-section .project-logo .new-kolkata-logo {
    width: 225px;
  }
}

/* .newkolkata-section .project-logo .flora-logo {
  width: 15vw;
} */

@media only screen and (max-width: 767px) {
  .newkolkata-section .project-logo .flora-logo {
    width: 275px;
  }
  .mobview {
    display: none !important;
  }
}

.newkolkata-section .container {
  max-width: 96%;
  width: 96%;
}

.newkolkata-section .project-img-box {
  display: inline-block;
  vertical-align: top;
  margin-right: -2px;
  background: white;
  border: solid 3px #fff;
  width: 37%;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 1500px) {
  .newkolkata-section .project-img-box {
    border: solid 2px #fff;
  }
}

@media only screen and (max-width: 1500px) {
  .newkolkata-section .project-img-box {
    border: solid 2px #fff;
  }
}

@media only screen and (max-width: 767px) {
  .newkolkata-section .project-img-box {
    padding: 2px;
  }
}

.newkolkata-section .project-img-box .mob_banners_home {
  display: none;
}

.newkolkata-section .project-img-box .parallax {
  position: relative;
  right: 0;
  background-position: center center;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .newkolkata-section .project-img-box .parallax {
    height: auto;
    background: none !important;
  }

  .newkolkata-section .project-img-box .parallax .mob_banners_home {
    display: block;
  }
}

@media only screen and (max-width: 479px) {
  .newkolkata-section .project-img-box .parallax {
    height: auto !important;
  }
}

.newkolkata-section .project-img-box.project01 {
  width: 63%;
}

@media only screen and (max-width: 767px) {
  .newkolkata-section .project-img-box.project01 {
    width: 100%;
  }
}

.newkolkata-section .project-img-box.project02 {
  margin-top: -4%;
}

@media only screen and (max-width: 767px) {
  .newkolkata-section .project-img-box.project02 {
    width: 50%;
    margin-top: 0;
  }
}

.newkolkata-section .project-img-box.project03 {
  border-right: 0;
}

@media only screen and (max-width: 767px) {
  .newkolkata-section .project-img-box.project03 {
    width: 50%;
  }
}

.newkolkata-section .project-img-box.project-text-box {
  margin-top: -5%;
  background: white;
  padding: 6px;
  position: relative;
}

@media only screen and (max-width: 1500px) {
  .newkolkata-section .project-img-box.project-text-box {
    padding: 4px;
  }
}

@media only screen and (max-width: 1023px) {
  .newkolkata-section .project-img-box.project-text-box {
    width: 45%;
  }
}

@media only screen and (max-width: 767px) {
  .newkolkata-section .project-img-box.project-text-box {
    width: 100%;
    padding: 2px 0;
    margin-top: 0;
    margin-right: 0;
  }
}

.newkolkata-section .project-img-box.project-text-box .project-inner-textbox {
  background: #006ebb;
  padding: 125px 90px;
}

@media only screen and (max-width: 1500px) {
  .newkolkata-section .project-img-box.project-text-box .project-inner-textbox {
    padding: 60px;
  }
}

@media only screen and (max-width: 1279px) {
  .newkolkata-section .project-img-box.project-text-box .project-inner-textbox {
    padding: 35px 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .newkolkata-section .project-img-box.project-text-box .project-inner-textbox {
    padding: 20px;
  }
}

.newkolkata-section .project-img-box.project-text-box p {
  color: white;
  margin: 35px 0;
}

@media only screen and (max-width: 1500px) {
  .newkolkata-section .project-img-box.project-text-box p {
    margin: 25px 0;
  }
}

@media only screen and (max-width: 1023px) {
  .newkolkata-section .project-img-box.project-text-box p {
    margin: 15px 0;
  }
}

.newkolkata-section .project-img-box.project-text-box .btn {
  color: white;
}

.newkolkata-section .project-img-box.project-text-box .btn:before,
.newkolkata-section .project-img-box.project-text-box .btn:after {
  background: white;
}

.newkolkata-section .project-img-box.project-text-box .btn:hover {
  color: #000;
}

.newkolkata-section .project-img-box.project-text-box .btn:hover span:before,
.newkolkata-section .project-img-box.project-text-box .btn:hover span:after {
  background: #000;
  width: 100%;
}

.newkolkata-section.flora-section .project-text-box .project-inner-textbox {
  background: #ffce00;
}

.newkolkata-section.flora-section .project-text-box .heading03,
.newkolkata-section.flora-section .project-text-box p {
  color: black;
}

/* .newkolkata-section.flora-section .project-text-box .btn {
  color: black;
} */

.newkolkata-section.flora-section .project-text-box .btn:before,
.newkolkata-section.flora-section .project-text-box .btn:after {
  background: black;
}

.newkolkata-section.flora-section .project-text-box .btn:hover {
  color: #fff;
}

.newkolkata-section.flora-section .project-text-box .btn:hover span:before,
.newkolkata-section.flora-section .project-text-box .btn:hover span:after {
  background: #fff;
}

.other-projects p {
  margin-top: 35px;
  max-width: 760px;
  width: 100%;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .other-projects p {
    margin-top: 15px;
  }
}

/* .other-projects .project-box-wrapper {
  margin-left: -5px;
  margin-top: 35px;
} */

.other-projects .project-box-wrapper .project-box {
  width: -webkit-calc(50% - 9px);
  width: -moz-calc(50% - 9px);
  width: -ms-calc(50% - 9px);
  width: calc(50% - 9px);
  margin-left: 5px;
  margin-top: 5px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .other-projects .project-box-wrapper .project-box {
    width: 100%;
    margin-left: 0;
  }
}

.other-projects .project-box-wrapper .project-box .project-name {
  position: absolute;
  top: 4%;
  right: 17%;
  text-align: right;
}

@media only screen and (max-width: 1023px) {
  .other-projects .project-box-wrapper .project-box .project-name {
    right: 22%;
  }
}

.other-projects .project-box-wrapper .project-box .project-name p {
  font-family: "DINRegularAlternate";
  font-weight: 800;
  color: white;
  margin-top: 15px;
  font-size: 15px;
  display: block;
}

@media only screen and (max-width: 1500px) {
  .other-projects .project-box-wrapper .project-box .project-name p {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1023px) {
  .other-projects .project-box-wrapper .project-box .project-name p {
    margin-top: 5px;
    font-size: 13px;
  }
}

.other-projects .project-box-wrapper .project-box .project-logo-box {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  bottom: 0;
  right: -15%;
  width: 15%;
  padding: 0 10px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-transition: ease 0.5s all;
  -moz-transition: ease 0.5s all;
  transition: ease 0.5s all;
}

@media only screen and (max-width: 1023px) {
  .other-projects .project-box-wrapper .project-box .project-logo-box {
    width: 20%;
  }
}

.other-projects .project-box-wrapper .project-box .project-logo-box img {
  width: 100%;
  display: block;
}
@media only screen and (max-width: 767px) {
  .other-projects .project-box-wrapper .project-box .project-logo-box {
    right: 0;
    width: 33%;
  }
  .other-projects .project-box-wrapper .project-box .project-name {
    right: 36%;
  }
}

.other-projects .project-box-wrapper .project-box .project-img {
  -webkit-transition: ease 1s all;
  -moz-transition: ease 1s all;
  transition: ease 1s all;
}

.other-projects .project-box-wrapper .project-box:hover .project-logo-box {
  right: 0;
}

.other-projects .project-box-wrapper .project-box:hover .project-img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.contactus-section {
  background: #f1f1f1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .contactus-section {
    display: block;
  }
}

.contactus-section .contactus-form-box {
  width: 50%;
  float: left;
  padding: 60px 0;
}

@media only screen and (max-width: 767px) {
  .contactus-section .contactus-form-box {
    width: 100%;
    float: none;
  }
}

@media only screen and (max-width: 1500px) {
  .contactus-section .contactus-form-box {
    padding: 30px 0;
  }
}

.contactus-section .contactus-form-box .contactus-inner-box {
  width: 100%;
  padding-left: 35%;
  padding-right: 3%;
}

@media only screen and (max-width: 1500px) {
  .contactus-section .contactus-form-box .contactus-inner-box {
    padding-left: 25%;
  }
}

@media only screen and (max-width: 1300px) {
  .contactus-section .contactus-form-box .contactus-inner-box {
    padding-left: 20%;
  }
}

@media only screen and (max-width: 1279px) {
  .contactus-section .contactus-form-box .contactus-inner-box {
    padding-left: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .contactus-section .contactus-form-box .contactus-inner-box {
    padding-right: 5%;
  }
}

.contactus-section .contactus-form-box .contactus-inner-box p {
  margin-top: 25px;
}

@media only screen and (max-width: 1500px) {
  .contactus-section .contactus-form-box .contactus-inner-box p {
    margin-top: 15px;
  }
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box {
  margin-top: 15px;
  position: relative;
}

@media only screen and (max-width: 1500px) {
  .contactus-section
    .contactus-form-box
    .contactus-inner-box
    .contactus-form
    .form-box {
    margin-top: 10px;
  }
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  input[type="text"],
.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  textarea {
  border: none;
  border-bottom: solid 1px #666666;
  padding: 5px 0;
  background: none;
  max-width: 285px;
  width: 100%;
}

@media only screen and (max-width: 1500px) {
  .contactus-section
    .contactus-form-box
    .contactus-inner-box
    .contactus-form
    .form-box
    input[type="text"],
  .contactus-section
    .contactus-form-box
    .contactus-inner-box
    .contactus-form
    .form-box
    textarea {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .contactus-section
    .contactus-form-box
    .contactus-inner-box
    .contactus-form
    .form-box
    input[type="text"],
  .contactus-section
    .contactus-form-box
    .contactus-inner-box
    .contactus-form
    .form-box
    textarea {
    max-width: 100%;
  }
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  input[type="text"]::-webkit-input-placeholder,
.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  textarea::-webkit-input-placeholder {
  color: #666666;
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  input[type="text"]::-moz-placeholder,
.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  textarea::-moz-placeholder {
  color: #666666;
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  input[type="text"]:-ms-input-placeholder,
.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  textarea:-ms-input-placeholder {
  color: #666666;
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  input[type="text"]:-moz-placeholder,
.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  textarea:-moz-placeholder {
  color: #666666;
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  textarea {
  height: 50px;
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  .checkbox-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 25px;
}

@media only screen and (max-width: 1500px) {
  .contactus-section
    .contactus-form-box
    .contactus-inner-box
    .contactus-form
    .form-box
    .checkbox-label {
    margin-top: 15px;
    padding-left: 25px;
  }
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  .checkbox-label
  input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  .checkmark {
  position: absolute;
  top: -4px;
  left: 0;
  height: 20px;
  width: 20px;
  border: solid 1px #666666;
  background: none;
}

@media only screen and (max-width: 1500px) {
  .contactus-section
    .contactus-form-box
    .contactus-inner-box
    .contactus-form
    .form-box
    .checkmark {
    width: 15px;
    height: 15px;
    top: -2px;
  }
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  .checkbox-label
  input:checked
  ~ .checkmark:after {
  display: block;
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .form-box
  .checkbox-label
  .checkmark:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid #666666;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .send-btn {
  color: #666666;
}

@media only screen and (max-width: 1500px) {
  .contactus-section
    .contactus-form-box
    .contactus-inner-box
    .contactus-form
    .send-btn {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .contactus-section
    .contactus-form-box
    .contactus-inner-box
    .contactus-form
    .send-btn {
    width: 100%;
  }

  .contactus-section
    .contactus-form-box
    .contactus-inner-box
    .contactus-form
    .send-btn
    span {
    width: 100%;
  }
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .send-btn:before,
.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .send-btn:after {
  background: #666666;
}

.contactus-section
  .contactus-form-box
  .contactus-inner-box
  .contactus-form
  .send-btn:hover {
  color: #006ebb;
}

.contactus-section .contactus-form-box .address-box p {
  display: block;
  width: 100%;
}

.contactus-section .contactus-bg {
  width: 50%;
  float: left;
  background: url("/assets/alcove_img_bw.jpg") no-repeat;
  background-position: center;
  background-size: cover;
}

.contactus-section .contactus-bg:hover {
  background: url("/assets/alcove_img_color.jpg") no-repeat;
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .contactus-section .contactus-bg {
    width: 100%;
    float: none;
    height: 300px;
  }
}

.fancybox-skin {
  background: #006ebb;
}

.blog_section .blog_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box {
    display: block;
  }
}

.blog_section .blog_box .left_70 {
  width: 70%;
  display: inline-block;
  vertical-align: top;
  maergin-left: -30px;
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box .left_70 {
    width: 100%;
  }
}

.blog_section .blog_box .left_70 .content {
  border-bottom: solid 1px #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  margin-bottom: 50px;
  width: -webkit-calc(50% - 34px);
  width: -moz-calc(50% - 34px);
  width: -ms-calc(50% - 34px);
  width: calc(50% - 34px);
  margin-left: 30px;
  float: left;
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box .left_70 .content {
    width: 100%;
    margin-left: 0px;
  }
}

.blog_section .blog_box .left_70 .content .img {
  position: relative;
}

.blog_section .blog_box .left_70 .content .img .date {
  position: absolute !important;
  bottom: 2px;
  left: 3%;
  text-align: center;
  width: 77px;
  height: 90px;
  background: #006ebb;
  color: #fff;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box .left_70 .content .img .date {
    width: 60px;
    height: 68px;
  }
}

.blog_section .blog_box .left_70 .content .img .date .day {
  font-family: "DINRegularAlternate", sans-serif;
  font-size: 42px;
  margin: 10px 10px 6px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box .left_70 .content .img .date .day {
    font-size: 28px;
  }
}

.blog_section .blog_box .left_70 .content .img .date .month {
  font-family: "DINRegularAlternate", sans-serif;
}

.blog_section .blog_box .left_70 .content .details_big {
  padding: 20px;
}

.blog_section .blog_box .left_70 .content .details_big .desc {
  border-bottom: 1px solid #ddd;
  padding-bottom: 13px;
  font-size: 14px;
}

.blog_section .blog_box .left_70 .content .details_big .desc .view_comments {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box .left_70 .content .details_big .desc .view_comments {
    display: block;
  }
}

.blog_section
  .blog_box
  .left_70
  .content
  .details_big
  .desc
  .view_comments
  .left_admin
  ul
  li {
  display: inline-block;
  padding: 0 3px;
}

.blog_section
  .blog_box
  .left_70
  .content
  .details_big
  .desc
  .view_comments
  .left_admin
  ul
  li
  p {
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .blog_section
    .blog_box
    .left_70
    .content
    .details_big
    .desc
    .view_comments
    .left_admin
    ul
    li {
    padding: 0 1px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_section
    .blog_box
    .left_70
    .content
    .details_big
    .desc
    .view_comments
    .left_admin {
    margin-bottom: 15px;
  }
}

.blog_section
  .blog_box
  .left_70
  .content
  .details_big
  .desc
  .view_comments
  .social
  ul
  li {
  display: inline-block;
  padding: 0 3px;
}

.blog_section .blog_box .left_70 .content .para {
  padding: 20px;
}

.blog_section .blog_box .left_70 .content .para h4 {
  margin-bottom: 15px;
  line-height: 24px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box .left_70 .content .para {
    text-align: justify;
  }
}

.blog_section .blog_box .right_30 {
  width: 28%;
  display: inline-block;
  vertical-align: top;
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box .right_30 {
    width: 100%;
  }
}

.blog_section .blog_box .right_30 .recent_box .panel {
  margin-bottom: 50px;
}

.blog_section .blog_box .right_30 .recent_box .panel h2 {
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #447e95;
}

.blog_section .blog_box .right_30 .recent_box .panel .cnt {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.blog_section .blog_box .right_30 .recent_box .panel .cnt .img_post {
  display: inline-block;
  vertical-align: top;
  width: 75px;
  height: 75px;
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box .right_30 .recent_box .panel .cnt .img_post {
    width: 60px;
    height: 60px;
  }
}

.blog_section .blog_box .right_30 .recent_box .panel .cnt .cont_data {
  width: calc(100% - 90px);
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box .right_30 .recent_box .panel .cnt .cont_data {
    width: calc(100% - 75px);
  }
}

.blog_section .blog_box .right_30 .recent_box .panel .cnt .cont_data h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.blog_section .blog_box .right_30 .recent_box .panel .cnt .cont_data p {
  line-height: 24px;
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box .right_30 .recent_box .panel .cnt .cont_data p {
    text-align: justify;
  }
}

.blog_section
  .blog_box
  .right_30
  .recent_box
  .panel
  .cnt
  .cont_data
  .read_more {
  font-weight: 700;
  font-family: "DINRegularAlternate-Bold";
  margin-top: 5px;
  display: block;
}

.blog_section .blog_box .right_30 .recent_box .panel .cat_list {
  width: 30%;
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box .right_30 .recent_box .panel .cat_list {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box .right_30 .recent_box .panel .cat_list {
    width: 100%;
  }
}

.blog_section .blog_box .right_30 .recent_box .panel .cat_list ul li {
  border-bottom: 1px solid #ddd;
  margin-bottom: 7px;
  padding: 10px 20px 10px 0;
}

.blog_section .blog_box .right_30 .recent_box .panel .cat_list ul li a {
  display: block;
  width: 100%;
}

.blog_section
  .blog_box
  .right_30
  .recent_box
  .panel
  .cat_list
  ul
  li:last-child {
  border-bottom: 0px solid #ddd;
}

.blog_section .blog_box .right_30 .recent_box .panel .subscribe_bx {
  width: 60%;
}

.blog_section .blog_box .right_30 .recent_box .panel .subscribe_bx .form_row {
  margin-bottom: 10px;
}

.blog_section
  .blog_box
  .right_30
  .recent_box
  .panel
  .subscribe_bx
  .form_row
  input[type="text"] {
  padding: 5px;
  width: 100%;
}

.blog_section
  .blog_box
  .right_30
  .recent_box
  .panel
  .subscribe_bx
  .form_row
  input[type="email"] {
  padding: 5px;
  width: 100%;
}

.blog_section
  .blog_box
  .right_30
  .recent_box
  .panel
  .subscribe_bx
  .form_row
  input[type="submit"] {
  width: 150px;
  display: block;
  background: #006ebb;
  color: white;
  text-transform: uppercase;
  padding: 9px 0;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
  margin-top: 20px;
  line-height: 13px;
  border: solid 3px #006ebb;
  -webkit-transition: ease 0.5s all;
  -moz-transition: ease 0.5s all;
  transition: ease 0.5s all;
  position: relative;
}

.blog_section .blog_box .blog-detail {
  width: 70%;
  float: left;
}

@media only screen and (max-width: 767px) {
  .blog_section .blog_box .blog-detail {
    width: 100%;
    float: none;
  }
}

.blog_section .blog_box .blog-detail a {
  color: #006ebb;
}

.blog_section .blog_box .blog-detail .left_70 {
  width: 100%;
}

.blog_section .blog_box .blog-detail .left_70 .content {
  float: none;
  margin-left: 0;
  width: 100%;
}

.pagination_list {
  width: 100%;
}

.pagination_list ul li {
  display: inline-block;
  vertical-align: middle;
  border: solid 1px #ddd;
}

.pagination_list ul li a {
  display: block;
  padding: 5px;
}

.media_section {
  padding: 50px 0 50px 0px;
}

.media_section .media_tab {
  padding: 0px 0 50px;
}

.media_section .media_tab ul li {
  display: inline-block;
  width: 180px;
  margin-right: 20px;
}

.media_section .media_tab ul li a {
  padding: 5px;
  color: white;
  font-family: "DINRegularAlternate";
  font-family: "DINRegularAlternate", sans-serif;
  font-size: 22px;
  background: #a1a1a1;
  padding: 7px 10px;
  display: block;
  text-align: center;
}

.media_section .media_tab ul li a.active {
  background: #006ebb;
}

@media only screen and (max-width: 767px) {
  .media_section .media_tab ul li a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .media_section .media_tab ul li {
    width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .media_section .media_tab {
    margin: 0 auto;
    text-align: center;
  }
}

.media_section .media_content .content_bx {
  display: none;
}

.media_section .media_content .content_bx .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px;
}

.media_section .media_content .content_bx .content .left_date {
  width: 76px;
  height: 88px;
  background: #006ebb;
  text-align: center;
  position: relative;
}

.media_section .media_content .content_bx .content .left_date div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.media_section .media_content .content_bx .content .left_date div h3 {
  color: white;
  font-size: 40px;
  font-family: "DINRegularAlternate", sans-serif;
  margin-bottom: 5px;
}

.media_section .media_content .content_bx .content .left_date div p {
  font-family: "DINRegularAlternate", sans-serif;
  color: white;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .media_section .media_content .content_bx .content .left_date {
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.media_section .media_content .content_bx .content .details {
  width: calc(100% - 125px);
}

.media_section .media_content .content_bx .content .details h3 {
  font-family: "DINRegularAlternate-Bold";
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .media_section .media_content .content_bx .content .details h3 {
    line-height: 24px;
    text-align: justify;
  }
}

.media_section .media_content .content_bx .content .details p {
  font-family: "DINRegularAlternate", sans-serif;
  line-height: 24px;
}

@media only screen and (max-width: 767px) {
  .media_section .media_content .content_bx .content .details {
    width: 98%;
    margin: 0 auto;
    text-align: justify;
  }
}

@media only screen and (max-width: 767px) {
  .media_section .media_content .content_bx .content {
    display: block;
  }
}

.media_section .media_content .content_bx.tab_content .active {
  display: block;
}

.contactus-section-bx {
  padding: 60px 0 20px;
}

.contactus-section-bx .bx {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .contactus-section-bx .bx {
    display: block;
  }
}

.contactus-section-bx .address-box {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .contactus-section-bx .address-box {
    width: 100%;
  }
}

.contactus-section-bx .address-box .top-box {
  padding: 10px 0 0px;
}

.contactus-section-bx .address-box .top-box h3 {
  font-size: 28px;
  margin-bottom: 10px;
  color: #006ebb;
}

@media only screen and (max-width: 767px) {
  .contactus-section-bx .address-box .top-box h3 {
    font-size: 24px;
  }
}

.contactus-section-bx .address-box .top-box p {
  line-height: 24px;
  margin-bottom: 20px;
}

.contactus-section-bx .address-box .top-box a {
  color: #585858;
}

.contactus-section-bx .address-box .bottom-box {
  margin-right: 10%;
  margin-top: 25px;
}

.contactus-section-bx .address-box .bottom-box .form-box {
  width: -webkit-calc(50% - 10px);
  width: -moz-calc(50% - 10px);
  width: -ms-calc(50% - 10px);
  width: calc(50% - 10px);
  margin-left: 6px;
  display: inline-block;
  vertical-align: middle;
  padding: 6px 10px;
}

@media only screen and (max-width: 639px) {
  .contactus-section-bx .address-box .bottom-box .form-box {
    width: 100%;
  }
}

.contactus-section-bx .address-box .bottom-box .form-box input {
  border: solid 2px #006ebb;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  width: 100%;
  padding: 5px 12px;
  font-size: 12px;
}

.contactus-section-bx .address-box .bottom-box .form-box textarea {
  border: solid 2px #006ebb;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  width: 100%;
  padding: 5px 12px;
  font-size: 12px;
  height: 60px;
  resize: none;
}

.contactus-section-bx .address-box .bottom-box .read-more {
  width: 300px;
  display: block;
  margin: 30px auto;
  background: #006ebb;
  color: white;
  text-transform: uppercase;
  font-size: 15.65px;
  padding: 9px 0;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
  margin-top: 30px;
  line-height: 13px;
  border: solid 3px #006ebb;
  -webkit-transition: ease 0.5s all;
  -moz-transition: ease 0.5s all;
  transition: ease 0.5s all;
  position: relative;
}

@media only screen and (max-width: 639px) {
  .contactus-section-bx .address-box .bottom-box .read-more {
    width: 150px;
  }
}

.contactus-section-bx .map-box {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .contactus-section-bx .map-box {
    width: 100%;
    margin-top: 20px;
  }
}

.contactus-section-bx .map-box iframe {
  width: 100%;
  height: 630px;
}

@media only screen and (max-width: 767px) {
  .contactus-section-bx .map-box iframe {
    height: 400px;
  }
}

.animation-element .slide-to-left {
  opacity: 0;
  -webkit-transform: translate(1000px, 0);
  -moz-transform: translate(1000px, 0);
  -ms-transform: translate(1000px, 0);
  -o-transform: translate(1000px, 0);
  transform: translate(1000px, 0);
  transition: ease 1s all;
}

@media only screen and (max-width: 767px) {
  .animation-element .slide-to-left {
    opacity: 0;
    -webkit-transform: translate(50px, 0);
    -moz-transform: translate(50px, 0);
    -ms-transform: translate(50px, 0);
    -o-transform: translate(50px, 0);
    transform: translate(100px, 0);
  }
}

.animation-element .slide-to-right {
  opacity: 0;
  -webkit-transform: translate(-1000px, 0);
  -moz-transform: translate(-1000px, 0);
  -ms-transform: translate(-1000px, 0);
  -o-transform: translate(-1000px, 0);
  transform: translate(-1000px, 0);
  transition: ease 1s all;
}

@media only screen and (max-width: 767px) {
  .animation-element .slide-to-right {
    opacity: 0;
    -webkit-transform: translate(50px, 0);
    -moz-transform: translate(50px, 0);
    -ms-transform: translate(50px, 0);
    -o-transform: translate(50px, 0);
    transform: translate(50px, 0);
  }
}

.animation-element.in-view .slide-to-left {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.animation-element.in-view .slide-to-right {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.locat_tab {
  background: #151515;
  padding: 25px;
  text-align: center;
}

.locat_tab .panel ul li {
  width: 14%;
  display: inline-block;
  vertical-align: middle;
  margin-left: -4px;
}

.locat_tab .panel ul li a {
  color: #fff;
  font-size: 12px;
}

.row-reverse {
  -webkit-flex-direction: row-reverse;
  -mozf-lex-direction: row-reverse;
  -o-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 767px) {
  .row-reverse {
    margin: 25px 0;
  }
}

.building_zone .build {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .building_zone .build {
    display: block;
  }
}

.building_zone .build .left_dv {
  /* width: 50%; */
  display: inline-block;
  vertical-align: middle;
}

@media only screen and (max-width: 767px) {
  .building_zone .build .left_dv {
    width: 100%;
  }
}

.building_zone .build .left_dv img {
  display: block;
  width: 100%;
  height: auto;
}

.building_zone .build .right_dv {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  padding: 20px 50px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .building_zone .build .right_dv {
    width: 100%;
  }
}

.building_zone .build .right_dv.img_content {
  background: url(/assets/about-inner/bg-img.jpg);
  width: 50%;
  background-position: center center;
}

@media only screen and (max-width: 767px) {
  .building_zone .build .right_dv.img_content {
    width: 100%;
  }
}

@media only screen and (max-width: 979px) {
  .building_zone .build .right_dv {
    padding: 20px 25px;
  }
}

.building_zone .build .details h2 {
  font-family: "DINRegularAlternate-Bold";
  font-size: 48px;
  color: #000;
  text-align: center;
  margin-bottom: 50px;
  text-transform: uppercase;
}

@media only screen and (max-width: 1500px) {
  .building_zone .build .details h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 979px) {
  .building_zone .build .details h2 {
    font-size: 35px;
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 639px) {
  .building_zone .build .details h2 {
    font-size: 26px;
    margin-bottom: 20px;
  }
}

.building_zone .build .details p {
  font-family: "DINRegularAlternate", sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: #454545;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1500px) {
  .building_zone .build .details p {
    font-size: 20px;
    line-height: 34px;
  }
}

@media only screen and (max-width: 979px) {
  .building_zone .build .details p {
    font-size: 18px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 639px) {
  .building_zone .build .details p {
    line-height: 22px;
    font-size: 15px;
  }
}

.building_zone.siddhartha {
  padding: 30px 0;
  margin: 0 auto;
  text-align: center;
}

.building_zone.siddhartha h2 {
  text-align: center;
}

.building_zone.siddhartha p {
  margin: 25px auto;
  max-width: 900px;
  width: 100%;
}

.faq_section {
  padding: 60px 0 20px;
}

@media only screen and (max-width: 767px) {
  .faq_section .faq_tab {
    display: none;
  }
}

.faq_section .faq_tab ul li {
  float: left;
  text-align: center;
}

.faq_section .faq_tab ul li a {
  display: block;
  border: solid 0px;
  padding: 12px 20px;
}

@media only screen and (max-width: 767px) {
  .faq_section .faq_tab ul li a {
    padding: 12px 7px;
  }
}

.faq_section .faq_tab ul li a.active {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 0px solid;
  position: relative;
  background: #fff;
}

.faq_section .faq_content {
  padding: 20px 30px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: -1px;
}

.faq_section .faq_content .faq-title {
  display: none;
}

@media only screen and (max-width: 767px) {
  .faq_section .faq_content {
    padding: 0;
    border: 0;
    margin-top: 0;
  }

  .faq_section .faq_content .faq-title {
    background: #006ebb;
    color: #fff;
    font-size: 15px;
    position: relative;
    padding: 12px 20px;
    display: block;
    margin-top: 15px;
  }

  .faq_section .faq_content .faq-title:after {
    position: absolute;
    content: "\f067";
    font-family: "FontAwesome";
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    font-size: 15px;
    text-align: center;
  }

  .faq_section .faq_content .faq-title.active:after {
    content: "\f068";
  }
}

.faq_section .faq_content .details {
  padding-top: 15px;
  padding-left: 20px;
}

@media only screen and (max-width: 767px) {
  .faq_section .faq_content .details {
    padding-left: 0;
  }
}

.faq_section .faq_content .details ol {
  list-style: decimal;
}

.faq_section .faq_content .details ol li {
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .faq_section .faq_content .details ol li {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .faq_section .faq_content .details ol li {
    font-size: 16px;
    line-height: 21px;
  }
}

.faq_section .faq_content .details ol li h3 {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 18px;
  font-family: "DINRegularAlternate-Regular";
  padding-left: 3px;
}

@media only screen and (max-width: 767px) {
  .faq_section .faq_content .details ol li h3 {
    font-size: 16px;
    line-height: 21px;
  }
}

@media only screen and (max-width: 639px) {
  .faq_section .faq_content .details ol li h3 {
    font-size: 14px;
  }
}

.faq_section .faq_content .details ol li p {
  line-height: 24px;
  margin-bottom: 7px;
}

@media only screen and (max-width: 767px) {
  .faq_section .faq_content .details ol li p {
    text-align: justify;
    font-size: 14px;
  }
}

.faq_section .faq_content .details ol li .listing {
  list-style: outside;
  margin-top: 25px;
}

.faq_section .faq_content .details ol li .listing li {
  margin-bottom: 12px;
}

.faq_section .faq_content .details ol li .faq_map {
  height: 300px;
  overflow: hidden;
}

.faq_section .faq_content .tab_content {
  display: none;
}

@media only screen and (max-width: 767px) {
  .faq_section .faq_content .tab_content {
    background: #f3f3f3;
    padding: 0 20px 10px 35px;
  }
}

.media_section_new .media_content .content .heading_tab h3 {
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  background: #006ebb;
  padding: 10px 20px 6px 5px;
  color: #fff;
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .media_section_new .media_content .content .heading_tab h3 {
    font-size: 14px;
    line-height: 18px;
  }
}

.media_section_new .media_content .content .heading_tab h3 .plus_btn {
  float: right;
  padding: 1px 6px;
  color: #fff;
  font-size: 30px;
}

.media_section_new .media_content .content .heading_tab h3:before {
  content: "+";
  padding: 1px 6px;
  color: #fff;
  font-size: 30px;
  position: absolute;
  right: 20px;
}

@media only screen and (max-width: 767px) {
  .media_section_new .media_content .content .heading_tab h3:before {
    right: 5px;
  }
}

.media_section_new .media_content .content .heading_tab h3.active:before {
  content: "-";
}

.media_section_new .media_content .content .heading_content {
  padding: 10px 25px;
}

.media_section_new .media_content .content .heading_content .iframe_bx {
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .media_section_new .media_content .content .heading_content .iframe_bx {
    padding: 5px;
    width: 100%;
  }

  .media_section_new
    .media_content
    .content
    .heading_content
    .iframe_bx
    iframe {
    width: 100%;
  }
}

.media_section_new
  .media_content
  .content
  .heading_content
  .media_gallery
  .media
  .images_box {
  /* width: 24%; */
  display: inline-block;
  vertical-align: top;
  padding: 20px;
}

@media only screen and (max-width: 767px) {
  .media_section_new
    .media_content
    .content
    .heading_content
    .media_gallery
    .media
    .images_box {
    padding: 3px;
    /* width: 30%; */
  }
}

.media_section_new .media_content .content .heading_content p {
  margin-bottom: 20px;
}

.Name {
  padding-left: 25px;
}

@media only screen and (max-width: 1023px) {
  .Name {
    padding: 15px;
  }
}

@media only screen and (max-width: 639px) {
  .Name {
    width: 100%;
  }
}

.Name .name_details {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1023px) {
  .Name .name_details {
    margin-bottom: 10px;
  }
}

.Name .name_details p {
  font-weight: 600;
}

.Name .name_details p ~ p {
  margin-top: 0px;
  font-weight: normal;
}

.Name p {
  line-height: 24px;
}

@media only screen and (max-width: 1023px) {
  .Name p {
    line-height: 20px;
  }
}

@media only screen and (max-width: 639px) {
  .Name p {
    text-align: justify;
  }
}

@media only screen and (max-width: 479px) {
  .Name p {
    line-height: 18px;
  }
}

@media only screen and (max-width: 639px) {
  .img_person {
    width: auto;
    text-align: center;
  }
}

.bb-custom-side p {
  font-family: "DINRegularAlternate", sans-serif;
}

.bb-custom-wrapper > nav a {
  margin-top: 20px;
}

.lagacy_tab {
  z-index: 99;
  background: #151515;
  padding: 16px 25px;
  text-align: center;
}

@media only screen and (max-width: 639px) {
  .lagacy_tab {
    padding: 10px;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .lagacy_tab {
    text-align: center;
  }
}

@media only screen and (max-width: 639px) {
  .lagacy_tab .container {
    width: 100%;
  }
}

.lagacy_tab .panel ul li {
  padding: 0px 50px;
  display: inline-block;
  vertical-align: middle;
  margin-left: -4px;
}

@media only screen and (max-width: 1279px) {
  .lagacy_tab .panel ul li {
    padding: 0 35px;
  }
}

@media only screen and (max-width: 979px) {
  .lagacy_tab .panel ul li {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .lagacy_tab .panel ul li {
    padding: 6px 0;
    width: 50%;
  }
}

.lagacy_tab .panel ul li a {
  color: #fff;
  font-size: 12px;
  padding: 5px 0px;
  line-height: 20px;
  text-transform: uppercase;
  display: block;
}

.lagacy_tab .panel ul li a.active {
  border-bottom: 2px solid #ddd;
}

.lagacy_tab.fixed-tabx {
  position: fixed;
  width: 100%;
}

.milestones_panel {
  padding: 50px 0 10px 0;
}

.milestones_panel .milestone .details p {
  line-height: 24px;
  margin-bottom: 5px;
}

.milestones_panel .milestone .details p span {
  font-weight: bold;
  margin-right: 15px;
  width: 30px;
  display: inline-block;
}

.milestones_panel .milestone .details .milestone_listing {
  margin-top: 25px;
}

.lagacy_panel {
  padding: 50px 0 10px 0;
}

.history_panel {
  padding: 50px 0 10px 0;
}

.history_panel .history .details p {
  margin-bottom: 10px;
  margin-top: 10px;
  line-height: 22px;
}

.notable_projects_panel .notable .details .content {
  margin-top: 15px;
}

.notable_projects_panel .notable .details .content .heading_tab h3 {
  margin-bottom: 25px;
  font-size: 22px;
  text-transform: uppercase;
  line-height: 22px;
  cursor: pointer;
  background: #006ebb;
  padding: 10px 6px;
  color: #fff;
  position: relative;
  text-align: center;
}

@media only screen and (max-width: 979px) {
  .notable_projects_panel .notable .details .content .heading_tab h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .notable_projects_panel .notable .details .content .heading_tab h3 {
    font-size: 16px;
  }
}

.notable_projects_panel .notable .details .content .heading_tab h3 .plus_btn {
  float: right;
  padding: 1px 6px;
  color: #fff;
  font-size: 30px;
}

.notable_projects_panel .notable .details .content .heading_tab h3:before {
  content: "+";
  padding: 1px 6px;
  color: #fff;
  font-size: 30px;
  position: absolute;
  right: 20px;
}

.notable_projects_panel
  .notable
  .details
  .content
  .heading_tab
  h3.active:before {
  content: "-";
}

.notable_projects_panel .notable .details .content .heading_content {
  display: block;
  /* padding: 10px 25px; */
}

.notable_projects_panel .notable .details .content .heading_content h4 {
  margin-bottom: 20px;
  line-height: 22px;
  font-size: 18px;
  font-weight: 600;
}

@media only screen and (max-width: 979px) {
  .notable_projects_panel .notable .details .content .heading_content h4 {
    font-size: 16px;
  }
}

.notable_projects_panel
  .notable
  .details
  .content
  .heading_content
  .project_box
  ol {
  list-style: decimal;
  padding: 0;
}

.notable_projects_panel
  .notable
  .details
  .content
  .heading_content
  .project_box
  ol
  li {
  margin-bottom: 20px;
  margin-left: 15px;
}

.notable_projects_panel
  .notable
  .details
  .content
  .heading_content
  .project_box
  ol
  li
  .project_details {
  padding: 10px;
  background: #ccc;
  margin-top: 12px;
}

.notable_projects_panel
  .notable
  .details
  .content
  .heading_content
  .project_box
  ol
  li
  .project_details
  table {
  padding: 5px;
}

.notable_projects_panel
  .notable
  .details
  .content
  .heading_content
  .project_box
  ol
  li
  .project_details
  table
  td {
  padding: 5px 20px;
}

.notable_projects_panel
  .notable
  .details
  .content
  .heading_content
  .project_box
  ol
  li
  p {
  margin-top: 15px;
}

.notable_projects_panel .notable .details .content .heading_content p {
  margin-bottom: 20px;
}

#loader {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  background-color: black;
  z-index: 999999;
  text-align: center;
}

#loading-image {
  position: absolute;
  top: 35%;
  left: 25%;
  text-align: center;
  width: 700px;
  z-index: 9999999;
  transition: ease 3s all;
}

@media only screen and (max-width: 767px) {
  #loading-image {
    left: 0;
    top: 45%;
    width: 100%;
  }
}

#loading-image.active {
  width: 315px;
  transform: none;
  top: 10px;
  left: -70px;
}

@media only screen and (max-width: 767px) {
  #loading-image.active {
    width: 210px;
    top: 3px;
    left: -43px;
  }
}

#loading-image.load {
  width: 250px;
  transform: none;
  top: 3px;
  left: -43px;
  margin: 0;
  position: fixed;
}

@media only screen and (max-width: 767px) {
  #loading-image.load {
    width: 210px;
  }
}

.flip-wrapper {
  margin-top: 35px;
  height: 350px;
}

@media only screen and (max-width: 1023px) {
  .flip-wrapper {
    height: 300px;
  }

  .flip-wrapper .owl-stage-outer {
    height: 300px;
  }

  .flip-wrapper .owl-stage-outer .owl-item img {
    margin: 0 auto;
    display: block;
    width: auto;
  }
}

@media only screen and (max-width: 767px) {
  .flip-wrapper {
    margin-bottom: 50px;
  }
}

.flip-wrapper .owl-prev {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
}

.flip-wrapper .owl-next {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
}

.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
  display: inline-block;
  width: 90%;
  padding: 0 1%;
  cursor: pointer;
  position: relative;
  margin: 0 auto;
}

@media only screen and (max-width: 1023px) {
  .flip-container {
    width: 100%;
  }
}

.flip-container:hover .flipper,
.flip-container.hover .flipper {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flipper {
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .flipper {
    margin: 0 auto;
    position: relative;
    display: block;
    height: 220px;
  }
}

.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 1023px) {
  .front,
  .back {
    right: 0;
    margin: 0 auto;
  }
}

.front {
  z-index: 2;
}

.back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.hide-img {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
}

.enqury_bx .btn {
  color: #006ebb;
  margin: 0 auto;
}

.enqury_bx .btn:before,
.enqury_bx .btn:after {
  background: #006ebb;
}

.enqury_bx .btn:hover {
  color: #000;
}

.enqury_bx .btn:hover span:before,
.enqury_bx .btn:hover span:after {
  background: #000;
}

.sub-btn.btn {
  color: #006ebb;
  margin: 20px auto 0;
  display: block;
}

.sub-btn.btn:before,
.sub-btn.btn:after {
  background: #006ebb;
}

.sub-btn.btn:hover {
  color: #000;
}

.sub-btn.btn:hover span:before,
.sub-btn.btn:hover span:after {
  background: #000;
}

.awd_cont {
  position: absolute;
  top: 185px;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 1023px) {
  .awd_cont {
    top: 0;
    position: relative;
  }
}

.welcome-section p {
  margin-top: 10px;
  margin-bottom: 0;
}

.welcome-section p + p {
  margin-top: 4px;
}

.read_more_btn {
  display: none;
}

@media only screen and (max-width: 767px) {
  .read_more_btn {
    display: inline-block;
    color: #006ebb !important;
  }
}

@media only screen and (max-width: 767px) {
  .hide_text {
    display: block;
  }
}

.video_iframe {
  padding-top: 30px;
}

.video_iframe .iframe .left_box {
  float: left;
  width: 50%;
  margin-bottom: 10px;
  padding: 10px;
}

.video_iframe .iframe .left_box iframe {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .video_iframe .iframe .left_box {
    width: 100%;
    float: none;
  }
}

.video_iframe .iframe .right_box {
  float: left;
  width: 50%;
  margin-bottom: 10px;
  padding: 10px;
}

.video_iframe .iframe .right_box iframe {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .video_iframe .iframe .right_box {
    width: 100%;
    float: none;
  }
}

.mb-15 {
  margin-bottom: 15px;
}

.feedback-rating-form#smileys {
  text-align: center;
}

.feedback-rating-form#smileys label {
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
  display: inline-block;
  font-weight: 600;
}

.feedback-rating-form#smileys input[type="radio"] {
  -webkit-appearance: none;
  width: 90px;
  height: 90px;
  display: inline-block;
  vertical-align: top;
  border: none;
  cursor: pointer;
  transition: border 0.2s ease;
  filter: grayscale(100%);
  margin: 0 10px;
  transition: all 0.2s ease;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
}

@media only screen and (max-width: 767px) {
  .feedback-rating-form#smileys input[type="radio"] {
    width: 60px;
    height: 60px;
    margin: 0 5px;
  }
}

@media only screen and (max-width: 479px) {
  .feedback-rating-form#smileys input[type="radio"] {
    width: 45px;
    height: 45px;
    margin: 0 3px;
  }
}

.feedback-rating-form#smileys input[type="radio"]:hover,
.feedback-rating-form#smileys input[type="radio"]:checked {
  filter: grayscale(0);
}

.feedback-rating-form#smileys input[type="radio"]:focus {
  outline: 0;
}

.feedback-rating-form#smileys input[type="radio"].sad {
  background: url("/sad.jpg") no-repeat center center;
  background-size: 90px auto;
}

@media only screen and (max-width: 767px) {
  .feedback-rating-form#smileys input[type="radio"].sad {
    background-size: 60px auto;
  }
}

@media only screen and (max-width: 479px) {
  .feedback-rating-form#smileys input[type="radio"].sad {
    background-size: 45px auto;
  }
}

.feedback-rating-form#smileys input[type="radio"].ok {
  background: url("/ok.jpg") no-repeat center center;
  background-size: 90px auto;
}

@media only screen and (max-width: 767px) {
  .feedback-rating-form#smileys input[type="radio"].ok {
    background-size: 60px auto;
  }
}

@media only screen and (max-width: 479px) {
  .feedback-rating-form#smileys input[type="radio"].ok {
    background-size: 45px auto;
  }
}

.feedback-rating-form#smileys input[type="radio"].neutral {
  background: url("/neutral.jpg") no-repeat center center;
  background-size: 90px auto;
}

@media only screen and (max-width: 767px) {
  .feedback-rating-form#smileys input[type="radio"].neutral {
    background-size: 60px auto;
  }
}

@media only screen and (max-width: 479px) {
  .feedback-rating-form#smileys input[type="radio"].neutral {
    background-size: 45px auto;
  }
}

.feedback-rating-form#smileys input[type="radio"].good {
  background: url("/good.jpg") no-repeat center center;
  background-size: 90px auto;
}

@media only screen and (max-width: 767px) {
  .feedback-rating-form#smileys input[type="radio"].good {
    background-size: 60px auto;
  }
}

@media only screen and (max-width: 479px) {
  .feedback-rating-form#smileys input[type="radio"].good {
    background-size: 45px auto;
  }
}

.feedback-rating-form#smileys input[type="radio"].happy {
  background: url("/happy.jpg") no-repeat center center;
  background-size: 90px auto;
}

@media only screen and (max-width: 767px) {
  .feedback-rating-form#smileys input[type="radio"].happy {
    background-size: 60px auto;
  }
}

@media only screen and (max-width: 479px) {
  .feedback-rating-form#smileys input[type="radio"].happy {
    background-size: 45px auto;
  }
}

.feedback-form {
  max-width: 550px;
  margin: 0 auto;
  background: #fff;
  padding: 25px;
  margin-top: 40px;
  border: 1px solid #e4e4e4;
  width: 90%;
  text-align: center;
}

.feedback-form .enroll-btn {
  width: 320px;
  margin: 0 auto;
  font-size: 12px;
}

.feedback-form .form-row {
  margin-bottom: 15px;
}

.feedback-form .form-row input[type="radio"] {
  margin-right: 3px;
  display: inline-block;
  vertical-align: middle;
}

.feedback-form .form-row .input-control {
  border: 0;
  background: #fff;
  height: 40px;
  padding: 0 15px;
  border: 1px solid #efefef;
  width: 100%;
  color: #666666;
}

.feedback-form .form-row label {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
}

.feedback-form .form-row .input-control::-webkit-input-placeholder {
  color: #666666;
}

.feedback-form .form-row .input-control::-moz-placeholder {
  color: #666666;
}

.feedback-form .form-row .input-control:-ms-input-placeholder {
  color: #666666;
}

.feedback-form .form-row .input-control:-moz-placeholder {
  color: #666666;
}

.feedback-form .form-row .submit-btn {
  display: block;
  border: 0;
  width: 100%;
  background: #006ebb;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  margin-top: 25px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.feedback-form .form-row textarea.input-control {
  height: 80px;
  padding: 15px 15px;
}

@media only screen and (max-width: 479px) {
  .feedback-form .form-row textarea.input-control {
    letter-spacing: 1px;
  }
}

.feedback-comment {
  margin: 25px 0;
}

.bold {
  font-weight: 600;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #0066b3;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  border: 0;
}

.ui-widget-header {
  background: #8dc63f;
}

.ui-slider-horizontal {
  height: 2px;
  width: 100%;
}

.ui-widget-content {
  border: 0;
  background: #707070;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -0.5em;
}

.ui-slider-pips .ui-slider-line {
  height: 15px;
}

.ui-slider-pips .ui-slider-label {
  top: 15px;
}

.ui-slider-horizontal.ui-slider-pips {
  margin-bottom: 1.4em;
}

.ui-slider-pips .ui-slider-label,
.ui-slider-pips .ui-slider-pip-hide {
  display: none;
}

.ui-slider-pips .ui-slider-pip-label .ui-slider-label {
  display: block;
}

.ui-slider-pips .ui-slider-pip {
  width: 2em;
  height: 1em;
  line-height: 1em;
  position: absolute;
  font-size: 0.8em;
  color: #999;
  overflow: visible;
  text-align: center;
  top: 20px;
  left: 20px;
  margin-left: -1em;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ui-state-disabled.ui-slider-pips .ui-slider-pip {
  cursor: default;
}

.ui-slider-pips .ui-slider-line {
  background: #999;
  width: 1px;
  height: 3px;
  position: absolute;
  left: 50%;
}

.ui-slider-pips .ui-slider-label {
  position: absolute;
  top: 5px;
  left: 50%;
  margin-left: -1em;
  width: 2em;
}

.ui-slider-pips:not(.ui-slider-disabled) .ui-slider-pip:hover .ui-slider-label {
  color: black;
  font-weight: bold;
}

.ui-slider-vertical.ui-slider-pips {
  margin-bottom: 1em;
  margin-right: 2em;
}

.ui-slider-vertical.ui-slider-pips .ui-slider-pip {
  text-align: left;
  top: auto;
  left: 20px;
  margin-left: 0;
  margin-bottom: -0.5em;
}

.ui-slider-vertical.ui-slider-pips .ui-slider-line {
  width: 3px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
}

.ui-slider-vertical.ui-slider-pips .ui-slider-label {
  top: 50%;
  left: 0.5em;
  margin-left: 0;
  margin-top: -0.5em;
  width: 2em;
}

.ui-slider-float .ui-slider-handle:focus,
.ui-slider-float .ui-slider-handle.ui-state-focus .ui-slider-tip-label,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip,
.ui-slider-float .ui-slider-handle.ui-state-focus .ui-slider-tip-label,
.ui-slider-float
  .ui-slider-handle:focus
  .ui-slider-tip-label
  .ui-slider-float
  .ui-slider-handle.ui-state-focus
  .ui-slider-tip-label {
  outline: none;
}

.ui-slider-float .ui-slider-tip,
.ui-slider-float .ui-slider-tip-label {
  position: absolute;
  visibility: hidden;
  top: -40px;
  display: block;
  width: 34px;
  margin-left: -18px;
  left: 50%;
  height: 20px;
  line-height: 20px;
  background: white;
  border-radius: 3px;
  border: 1px solid #888;
  text-align: center;
  font-size: 12px;
  opacity: 0;
  color: #333;
  -webkit-transition-property: opacity, top, visibility;
  transition-property: opacity, top, visibility;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-transition-duration: 200ms, 200ms, 0ms;
  transition-duration: 200ms, 200ms, 0ms;
  -webkit-transition-delay: 0ms, 0ms, 200ms;
  transition-delay: 0ms, 0ms, 200ms;
}

.ui-slider-float .ui-slider-handle:hover .ui-slider-tip,
.ui-slider-float .ui-slider-handle.ui-state-hover .ui-slider-tip,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip,
.ui-slider-float .ui-slider-handle.ui-state-focus .ui-slider-tip,
.ui-slider-float .ui-slider-handle.ui-state-active .ui-slider-tip,
.ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
  opacity: 1;
  top: -30px;
  visibility: visible;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 200ms, 200ms, 0ms;
  transition-delay: 200ms, 200ms, 0ms;
}

.ui-slider-float .ui-slider-pip .ui-slider-tip-label {
  top: 42px;
}

.ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
  top: 32px;
  font-weight: normal;
}

.ui-slider-float .ui-slider-tip:after,
.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
  content: " ";
  width: 0;
  height: 0;
  border: 5px solid rgba(255, 255, 255, 0);
  border-top-color: white;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
}

.ui-slider-float .ui-slider-tip:before,
.ui-slider-float .ui-slider-pip .ui-slider-tip-label:before {
  content: " ";
  width: 0;
  height: 0;
  border: 5px solid rgba(255, 255, 255, 0);
  border-top-color: #888;
  position: absolute;
  bottom: -11px;
  left: 50%;
  margin-left: -5px;
}

.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
  border: 5px solid rgba(255, 255, 255, 0);
  border-bottom-color: white;
  top: -10px;
}

.ui-slider-float .ui-slider-pip .ui-slider-tip-label:before {
  border: 5px solid rgba(255, 255, 255, 0);
  border-bottom-color: #888;
  top: -11px;
}

.ui-slider-vertical.ui-slider-float .ui-slider-tip,
.ui-slider-vertical.ui-slider-float .ui-slider-tip-label {
  top: 50%;
  margin-top: -11px;
  width: 34px;
  margin-left: 0px;
  left: -60px;
  color: #333;
  -webkit-transition-duration: 200ms, 200ms, 0;
  transition-duration: 200ms, 200ms, 0;
  -webkit-transition-property: opacity, left, visibility;
  transition-property: opacity, left, visibility;
  -webkit-transition-delay: 0, 0, 200ms;
  transition-delay: 0, 0, 200ms;
}

.ui-slider-vertical.ui-slider-float .ui-slider-handle:hover .ui-slider-tip,
.ui-slider-vertical.ui-slider-float
  .ui-slider-handle.ui-state-hover
  .ui-slider-tip,
.ui-slider-vertical.ui-slider-float .ui-slider-handle:focus .ui-slider-tip,
.ui-slider-vertical.ui-slider-float
  .ui-slider-handle.ui-state-focus
  .ui-slider-tip,
.ui-slider-vertical.ui-slider-float
  .ui-slider-handle.ui-state-active
  .ui-slider-tip,
.ui-slider-vertical.ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
  top: 50%;
  margin-top: -11px;
  left: -50px;
}

.ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label {
  left: 47px;
}

.ui-slider-vertical.ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
  left: 37px;
}

.ui-slider-vertical.ui-slider-float .ui-slider-tip:after,
.ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
  border: 5px solid rgba(255, 255, 255, 0);
  border-left-color: white;
  border-top-color: transparent;
  position: absolute;
  bottom: 50%;
  margin-bottom: -5px;
  right: -10px;
  margin-left: 0;
  top: auto;
  left: auto;
}

.ui-slider-vertical.ui-slider-float .ui-slider-tip:before,
.ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label:before {
  border: 5px solid rgba(255, 255, 255, 0);
  border-left-color: #888;
  border-top-color: transparent;
  position: absolute;
  bottom: 50%;
  margin-bottom: -5px;
  right: -11px;
  margin-left: 0;
  top: auto;
  left: auto;
}

.ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
  border: 5px solid rgba(255, 255, 255, 0);
  border-right-color: white;
  right: auto;
  left: -10px;
}

.ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label:before {
  border: 5px solid rgba(255, 255, 255, 0);
  border-right-color: #888;
  right: auto;
  left: -11px;
}

.ui-slider-pips [class*="ui-slider-pip-initial"] {
  font-weight: bold;
  color: #14ca82;
}

.ui-slider-pips .ui-slider-pip-initial-2 {
  color: #1897c9;
}

.ui-slider-pips [class*="ui-slider-pip-selected"] {
  font-weight: bold;
  color: #ff7a00;
}

.ui-slider-pips .ui-slider-pip-inrange {
  color: black;
}

.ui-slider-pips .ui-slider-pip-selected-2 {
  color: #e70081;
}

.ui-slider-pips [class*="ui-slider-pip-selected"] .ui-slider-line,
.ui-slider-pips .ui-slider-pip-inrange .ui-slider-line {
  background: black;
}

.guide-section .heading02 {
  font-size: 18px;
  text-transform: initial;
  margin-top: 25px;
}

.guide-section ul {
  padding-left: 30px;
}

.guide-section ul li {
  font-size: 14px;
  margin-top: 10px;
  list-style-type: disc;
}

.guide-section .toggle-title {
  font-size: 18px;
  padding: 10px 15px;
}

.learn-more-no-pad {
  width: 100%;
  text-align: center;
}

.learn-more-no-pad input {
  background: #0066b3;
  color: #fff;
  border: 1px solid #0066b3;
  padding: 10px;
  text-transform: uppercase;
  border-radius: 6px;
}
@media only screen and (max-width: 1300px) {
}
@media only screen and (max-width: 767px) {
  .footer-social-list01 {
    width: 30px;
  }

  .footer-social-list01 i {
    padding-right: 12px;
  }

  .submit_query {
    width: 30px;
  }
}

.buyers-guide .container {
  width: 95%;
}

@media only screen and (max-width: 767px) {
  .buyers-guide .total-container {
    margin-bottom: 60px;
  }

  .buyers-guide .toggle-title {
    font-size: 12px;
    line-height: 12px;
  }

  .buyers-guide .lagacy_panel {
    padding-top: 10px;
  }

  .buyers-guide .emi_calculators {
    width: 96% !important;
  }

  .buyers-guide .emi_calculators .calculator-pan {
    padding: 10px;
  }

  .buyers-guide .left_calc {
    width: 100% !important;
    display: block;
    max-width: 300px !important;
    margin: 0 auto;
    padding-left: 10px;
  }

  .buyers-guide .left_calc .rupees input {
    width: 100px !important;
  }

  .buyers-guide .left_calc .home-loan-bx .box-top h4 {
    width: 110px;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
  }

  .buyers-guide .left_calc .home-loan-bx .ratebox_every {
    width: 100%;
  }

  .buyers-guide .left_calc .ui-slider-label {
    font-size: 10px;
    line-height: 10px;
  }

  .buyers-guide .right_calc {
    margin-top: 40px;
    display: block;
    width: 100%;
    text-align: center;
  }
}

.tag_title {
  margin-top: 20px;
}

.tag_title span {
  font-size: 18px;
  font-weight: bold;
}
