/*RESET CSS*/

.clear {
  clear: both;
  display: block;
  width: 100%;
}

/** responsive css **/

#bubble-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
}

#canvas-wrapper {
  position: relative;
}

.initiative-section .check-overlay,
.banner-btn,
.input-control,
.submit-btn {
  transition: all linear 0.5s;
}

.header .logo-section {
  width: 225px;
  margin: 0 auto;
  padding: 20px 0;
}
.header .logo-section a {
  display: inline-block;
  margin-right: -7px;
  vertical-align: middle;
  width: 50%;
  padding-right: 9px;
}
.header .logo-section a:last-child {
  padding-left: 9px;
  border-left: solid 1px #b3c5d6;
  padding-right: 0;
}

#main {
  background: url(/assets/back.jpg) no-repeat left top;
  background-size: cover;
}
@media only screen and (max-width: 1023px) {
  #main {
    background: url(/assets/back.jpg) no-repeat center top;
    overflow: hidden;
    background-size: cover;
  }
}

.banner-section {
  position: relative;
}
.banner-section .banner-img {
  width: 100%;
}
.banner-section .banner-container {
  position: relative;
  max-width: 700px;
  width: 95%;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
  text-align: center;
}
.banner-section .banner-container h2 {
  font-size: 50px;
  color: #f79433;
  font-family: "DINBold";
  margin-bottom: 15px;
}
@media only screen and (max-width: 1023px) {
  .banner-section .banner-container h2 {
    font-size: 35px;
  }
}
.banner-section .banner-container .banner-slider .banner-item p {
  font-size: 16px;
  line-height: 20px;
}
@media only screen and (max-width: 1023px) {
  .banner-section .banner-container .banner-slider .banner-item p {
    font-size: 15px;
  }

  .banner-section .banner-container .banner-slider .banner-item p br {
    display: none;
  }
}
.banner-section .banner-container .banner-slider .owl-dots {
  text-align: center;
  width: 100%;
  margin-top: 15px;
}
.banner-section .banner-container .banner-slider .owl-dots .owl-dot {
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  background: #ccc;
  margin: 0 2px;
}
.banner-section .banner-container .banner-slider .owl-dots .owl-dot.active {
  background: #f79433;
}
.banner-section .banner-container .banner-btn {
  color: #1f1f1f;
  font-family: "DINBold";
  text-transform: uppercase;
  color: #1f1f1f;
  font-size: 24px;
  text-align: center;
  display: inline-block;
  display: inline-block;
  padding: 10px 20px;
  border: 3px solid #1f1f1f;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
  border-radius: 35px;
  margin-top: 15px;
}
.banner-section .banner-container .banner-btn:hover {
  border-color: #1f1f1f;
  background: #1f1f1f;
  color: #fff;
}
.banner-section .banner-container .pledge-ques {
  color: #f79433;
  display: block;
  text-align: center;
  margin-top: 8px;
  font-family: "DINBold";
}
@media only screen and (max-width: 979px) {
  .banner-section .banner-container .pledge-ques {
    background: #f79433;
    color: #fff;
    padding: 8px 15px;
    display: inline-block;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
  }
}
.banner-section .banner-container .counter {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  width: 240px;
  right: -255px;
}
@media only screen and (max-width: 979px) {
  .banner-section .banner-container .counter {
    position: relative;
    right: 0;
    left: 0;
    margin: 0 auto 15px auto;
  }
}
.banner-section .banner-container .counter .count {
  margin-bottom: 10px;
  color: white;
  font-family: "DIN-Black";
}
.banner-section .banner-container .counter .count span {
  float: left;
  display: inline-block;
  width: 35px;
  height: 55px;
  line-height: 51px;
  margin-left: 5px;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  font-size: 25px;
  vertical-align: top;
  background-color: #183570;
}
.banner-section .banner-container .counter p {
  font-size: 16px;
  font-family: "DINBold";
  text-align: right;
  color: #183570;
}

.initiative-section {
  color: white;
  padding: 20% 0 80px 0;
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 1023px) {
  .initiative-section {
    padding: 7% 0 80px 0;
  }
}
.initiative-section .container {
  max-width: 920px;
  margin: 0 auto;
  width: 95%;
}
.initiative-section .container .section-txt {
  font-size: 20px;
  text-align: center;
  padding-bottom: 30px;
}
@media only screen and (max-width: 1023px) {
  .initiative-section .container .section-txt {
    padding: 30% 0 40px 0;
    text-align: center;
  }
}
.initiative-section .container ul li .initiative-grid {
  max-width: 400px;
  position: relative;
}
.initiative-section .container ul li .initiative-grid.odd:after {
  position: absolute;
  content: "\f100";
  font-family: "FontAwesome";
  right: -10%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 25px;
  color: #f79433;
}
.initiative-section .container ul li .initiative-grid.even:before {
  position: absolute;
  content: "\f101";
  font-family: "FontAwesome";
  left: -10%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 25px;
  color: #f79433;
}
.initiative-section .container ul li .initiative-grid figure {
  width: 165px;
  height: 165px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background: url(/assets/bubbles_icon.png) no-repeat center center;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s linear;
}
@media only screen and (max-width: 1023px) {
  .initiative-section .container ul li .initiative-grid figure {
    width: 130px;
    height: 130px;
  }
}
@media only screen and (max-width: 639px) {
  .initiative-section .container ul li .initiative-grid figure {
    width: 100px;
    height: 100px;
  }
}
.initiative-section .container ul li .initiative-grid figure .initiative-img {
  width: 165px;
  height: 165px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.initiative-section
  .container
  ul
  li
  .initiative-grid
  figure
  .initiative-img
  img {
  max-width: 90%;
  max-height: 90%;
}
@media only screen and (max-width: 1023px) {
  .initiative-section .container ul li .initiative-grid figure .initiative-img {
    width: 130px;
    height: 130px;
  }

  .initiative-section
    .container
    ul
    li
    .initiative-grid
    figure
    .initiative-img
    img {
    width: 70px;
  }
}
@media only screen and (max-width: 639px) {
  .initiative-section .container ul li .initiative-grid figure .initiative-img {
    width: 100px;
    height: 100px;
  }

  .initiative-section
    .container
    ul
    li
    .initiative-grid
    figure
    .initiative-img
    img {
    width: 45px;
  }
}
.initiative-section .container ul li .initiative-grid figure .check-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f79433 url(/assets/checkin_icon.png) no-repeat center center;
  border: 3px solid white;
  z-index: 9;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
}
@media only screen and (max-width: 1023px) {
  .initiative-section .container ul li .initiative-grid figure .check-overlay {
    background-size: 80px auto;
  }
}
@media only screen and (max-width: 639px) {
  .initiative-section .container ul li .initiative-grid figure .check-overlay {
    background-size: 55px auto;
  }
}
.initiative-section
  .container
  ul
  li
  .initiative-grid
  figure.active
  .check-overlay {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.initiative-section .container ul li .initiative-grid figcaption {
  color: white;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
}
.initiative-section .container ul li .initiative-grid input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.initiative-section .container ul li .initiative-grid:hover figure {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  transition: all 0.5s linear;
}
.initiative-section .container ul li:nth-child(odd) {
  text-align: left;
}
.initiative-section .container ul li:nth-child(odd) .initiative-grid {
  float: left;
}
.initiative-section .container ul li:nth-child(odd) .initiative-grid figure {
  margin-right: 10px;
}
.initiative-section .container ul li:nth-child(even) {
  text-align: right;
}
.initiative-section .container ul li:nth-child(even) .initiative-grid {
  float: right;
}
.initiative-section .container ul li:nth-child(even) .initiative-grid figure {
  margin-right: 10px;
}

.competition-section {
  padding-bottom: 80px;
  z-index: 99;
  position: relative;
}
.competition-section .container {
  max-width: 920px;
  margin: 0 auto;
  width: 95%;
}
.competition-section .container .form-container .form-row {
  width: 50%;
  padding: 0 15px;
  margin-right: -4px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 35px;
}
@media only screen and (max-width: 479px) {
  .competition-section .container .form-container .form-row {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.competition-section .container .form-container .form-row .input-control {
  width: 100%;
  height: 45px;
  border: 2px solid white;
  color: white;
  font-family: "DINRegularAlternate";
  padding: 0 15px;
  font-size: 14px;
  background: transparent;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
}
.competition-section .container .form-container .form-row .input-control:focus {
  border-color: #f79433;
}
.competition-section
  .container
  .form-container
  .form-row
  .input-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: white;
}
.competition-section
  .container
  .form-container
  .form-row
  .input-control::-moz-placeholder {
  /* Firefox 19+ */
  color: white;
}
.competition-section
  .container
  .form-container
  .form-row
  .input-control:-ms-input-placeholder {
  /* IE 10+ */
  color: white;
}
.competition-section
  .container
  .form-container
  .form-row
  .input-control:-moz-placeholder {
  /* Firefox 18- */
  color: white;
}
.competition-section .container .form-container .submit-btn {
  color: #161a45;
  background: #f79433;
  text-align: center;
  text-transform: uppercase;
  width: 270px;
  height: 55px;
  line-height: normal;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
  border: 0;
  font-family: "DINBold";
  font-size: 20px;
  margin: 0 auto;
  display: block;
  border: 3px solid transparent;
}
.competition-section .container .form-container .submit-btn:hover {
  border-color: #1f1f1f;
  background: #1f1f1f;
  color: #fff;
}

.bubble {
  position: absolute;
}

@-webkit-keyframes animateBubble {
  0% {
    margin-top: 1000px;
  }

  100% {
    margin-top: -100%;
  }
}

@-moz-keyframes animateBubble {
  0% {
    margin-top: 1000px;
  }

  100% {
    margin-top: -100%;
  }
}

@keyframes animateBubble {
  0% {
    margin-top: 1000px;
  }

  100% {
    margin-top: -100%;
  }
}

@-webkit-keyframes sideWays {
  0% {
    margin-left: 0px;
  }

  100% {
    margin-left: 50px;
  }
}

@-moz-keyframes sideWays {
  0% {
    margin-left: 0px;
  }

  100% {
    margin-left: 50px;
  }
}

@keyframes sideWays {
  0% {
    margin-left: 0px;
  }

  100% {
    margin-left: 50px;
  }
}

/* ANIMATIONS */
.x1 {
  -webkit-animation: animateBubble 25s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 25s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 25s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: -5%;
  top: 5%;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
}

.x2 {
  -webkit-animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 5%;
  top: 80%;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}

.x3 {
  -webkit-animation: animateBubble 28s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 28s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 28s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 10%;
  top: 40%;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transform: scale(0.7);
}

.x4 {
  -webkit-animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  left: 20%;
  top: 0;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}

.x5 {
  -webkit-animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 30%;
  top: 50%;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
}

.x6 {
  -webkit-animation: animateBubble 21s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 21s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 21s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 50%;
  top: 0;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
}

.x7 {
  -webkit-animation: animateBubble 20s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 20s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 20s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 65%;
  top: 70%;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}

.x8 {
  -webkit-animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  left: 80%;
  top: 10%;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}

.x9 {
  -webkit-animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 90%;
  top: 50%;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
}

.x10 {
  -webkit-animation: animateBubble 26s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 26s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 26s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 80%;
  top: 80%;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}

.x11 {
  -webkit-animation: animateBubble 24s linear infinite,
    sideWays 2.2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 24s linear infinite,
    sideWays 2.2s ease-in-out infinite alternate;
  animation: animateBubble 24s linear infinite,
    sideWays 2.2s ease-in-out infinite alternate;
  left: 50%;
  top: 60%;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}

.x12 {
  -webkit-animation: animateBubble 22s linear infinite,
    sideWays 5.5s ease-in-out infinite alternate;
  -moz-animation: animateBubble 22s linear infinite,
    sideWays 5.5s ease-in-out infinite alternate;
  animation: animateBubble 22s linear infinite,
    sideWays 5.5s ease-in-out infinite alternate;
  left: 70%;
  top: 30%;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
}

.x13 {
  -webkit-animation: animateBubble 26s linear infinite,
    sideWays 2.3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 26s linear infinite,
    sideWays 2.3s ease-in-out infinite alternate;
  animation: animateBubble 26s linear infinite,
    sideWays 2.3s ease-in-out infinite alternate;
  left: 55%;
  top: 80%;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}

/* OBJECTS */
.bubble {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px white;
  -moz-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px white;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px white;
  height: 100px;
  position: absolute;
  width: 100px;
}

.bubble:after {
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(255, 255, 255, 0.5)),
    color-stop(70%, rgba(255, 255, 255, 0))
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80ffffff', endColorstr='#00ffffff',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.6);
  -moz-box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.6);
  box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.6);
  content: "";
  height: 90px;
  left: 10px;
  position: absolute;
  width: 90px;
}

.sign-up-btn {
  color: #161a45;
  background: #f79433;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 45px;
  border: 0;
  font-family: "DINBold";
  font-size: 20px;
  margin: 0 auto;
  display: block;
  border: 3px solid transparent;
  z-index: 99999;
  line-height: 42px;
  display: none;
}
@media only screen and (max-width: 767px) {
  .sign-up-btn {
    display: block;
  }
}
.sign-up-btn:hover {
  border-color: #1f1f1f;
  background: #1f1f1f;
  color: #fff;
}

.thank-you-msg {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 100px 0;
  color: #171c44;
}
.thank-you-msg p {
  font-size: 20px;
  line-height: 28px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.7);
  display: none;
}

.popup {
  max-width: 600px;
  width: 90%;
  position: fixed;
  box-shadow: 0 0 10px #ccc;
  display: none;
  background: #fff;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999999;
}
.popup .popup-container {
  padding: 40px;
}
@media only screen and (max-width: 767px) {
  .popup .popup-container {
    padding: 15px;
    height: 400px;
    overflow: auto;
  }
}
.popup .popup-container p {
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .popup .popup-container p {
    margin-bottom: 10px;
  }
}
.popup .close-btn {
  position: absolute;
  right: -10px;
  top: -10px;
  color: #ffffff;
  display: inline-block;
  padding: 10px;
  background: #f79433;
}
.popup p {
  color: #171c44;
  font-size: 13px;
  line-height: 20px;
}
.popup .theme-btn {
  color: #161a45;
  background: #f79433;
  text-align: center;
  text-transform: uppercase;
  width: 220px;
  line-height: 40px;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
  border: 0;
  font-family: "DINBold";
  font-size: 16px;
  margin: 25px auto 0 auto;
  display: block;
  border: 3px solid transparent;
}
.popup .theme-btn:hover {
  border-color: #1f1f1f;
  background: #1f1f1f;
  color: #fff;
}

@-webkit-keyframes bubble-animation {
  0% {
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes bubble-animation {
  0% {
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes bubble-animation {
  0% {
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.initiative-grid figure:hover {
  -webkit-animation: bubble-animation 2s ease-in-out infinite alternate;
  -moz-animation: bubble-animation 2s ease-in-out infinite alternate;
  animation: bubble-animation 2s ease-in-out infinite alternate;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.ps-facebook-icon,
.ps-twitter-icon {
  color: #ffffff;
  display: inline-block;
  width: 35px;
  padding-top: 9px;
  height: 35px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  margin: 15px 1px 0 1px;
}

.ps-facebook-icon {
  background: #3b5998;
}

.ps-twitter-icon {
  background: #00aced;
}

.footer {
  background: #f6f6f6;
  padding: 30px 0 20px 0;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .footer {
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 479px) {
  .footer {
    padding: 25px 0;
    padding-bottom: 75px;
  }
}
.footer .footer-menu-list {
  text-align: left;
  margin: 0 auto;
  border-bottom: solid 2px #babcc7;
  padding-bottom: 35px;
  margin-bottom: 25px;
}
@media only screen and (max-width: 767px) {
  .footer .footer-menu-list {
    width: 100;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }
}
.footer .footer-menu-list li {
  display: inline-block;
  padding: 12px 0;
  width: 20%;
  margin-right: -6px;
  text-align: left;
  vertical-align: top;
  padding-left: 30px;
}
@media only screen and (max-width: 1023px) {
  .footer .footer-menu-list li {
    width: 25%;
  }
}
@media only screen and (max-width: 767px) {
  .footer .footer-menu-list li {
    padding-left: 0;
    width: 33.33%;
    padding: 10px 0;
  }
}
@media only screen and (max-width: 479px) {
  .footer .footer-menu-list li {
    width: 50%;
    padding: 7px 0;
    text-align: center;
  }
}
.footer .footer-menu-list li a {
  color: white;
  font-size: 15.65px;
}
@media only screen and (max-width: 767px) {
  .footer .footer-menu-list li a {
    font-size: 15px;
  }
}
@media only screen and (max-width: 479px) {
  .footer .footer-menu-list li a {
    font-size: 14px;
  }
}
.footer .marketing-list {
  width: 100%;
  text-align: center;
}
.footer .marketing-list li {
  display: inline-block;
  vertical-align: middle;
  padding: 0 40px;
}
@media only screen and (max-width: 1023px) {
  .footer .marketing-list li {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .footer .marketing-list li {
    padding: 0 5px;
  }
}
@media only screen and (max-width: 479px) {
  .footer .marketing-list li {
    vertical-align: top;
    width: 100%;
    margin-right: -4px;
    padding: 0;
  }
}
.footer .marketing-list li span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  color: #161b44;
  font-size: 15.65px;
}
@media only screen and (max-width: 639px) {
  .footer .marketing-list li span {
    display: block;
    margin-bottom: 15px;
    padding-left: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .footer .marketing-list li span.nk-logo {
    width: 60px;
  }
}
@media only screen and (max-width: 1023px) {
  .footer .marketing-list li span.sbi-logo {
    width: 150px;
  }
}
@media only screen and (max-width: 479px) {
  .footer .marketing-list li span.sbi-logo {
    width: 100%;
  }
}
@media only screen and (max-width: 1023px) {
  .footer .marketing-list li span .igbc-logo {
    width: 40px;
  }
}
.footer .copy-right {
  margin-top: 45px;
  font-size: 12.8px;
}
@media only screen and (max-width: 1023px) {
  .footer .copy-right {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .footer .copy-right {
    margin-top: 15px;
  }
}
.footer .footer-social-list {
  margin-top: 15px;
}
.footer .footer-social-list a {
  color: white;
  font-size: 24px;
  padding: 0 18px;
}

/** new footer **/
.new-footer {
  padding: 0;
  background: #fff;
}
@media only screen and (max-width: 767px) {
  .new-footer {
    padding-bottom: 45px;
  }
}
.new-footer .footer-menu {
  background: #171c44;
  padding: 40px 0 30px;
}
.new-footer .footer-menu .footer-menu-list {
  padding: 0;
  border-bottom: none;
  margin-bottom: 0;
}
.new-footer .footer-menu .footer-menu-list li a {
  color: #fff;
}
.new-footer .marketing-parter {
  padding: 25px 0;
  text-align: center;
}
.new-footer .marketing-parter .marketing-list li span {
  color: #171c44;
  font-family: "DINBold";
  margin-left: 25px;
}
.new-footer .copy-right-wrapper {
  padding: 25px 0;
  background: #171c44;
}
.new-footer .copy-right-wrapper p {
  margin-top: 0;
  color: #fff;
}
.new-footer .copy-right-wrapper .footer-social-list a {
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .footer .footer-social-list a {
    font-size: 22px;
    padding: 0 15px;
  }
  .new-footer .marketing-parter .marketing-list li span {
    margin-left: 0;
  }
  .footer .marketing-list li span.nk-logo {
    width: 100%;
  }
  .banner-section .banner-container h2 {
    font-size: 25px;
  }
}
