@font-face {
  font-family: "DINRegularAlternate";
  src: url("./../public/fonts/DINRegularAlternate.ttf");
}
@font-face {
  font-family: "DINBold";
  src: url("./../public/fonts/DINAlternateBold.otf");
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
:root {
  --color-accent-primary: #f79433;
  --color-accent-secondary: #171c44;
  --color-white: #fff;
  --green: #80bcb8;
  --greybg: #f9f9f9;
  --grey: #666666;
}

body {
  font-family: "DINRegularAlternate";
  font-size: 16px !important;
  line-height: 1.6 !important;
  color: var(--color-accent-secondary);
}
section {
  overflow-x: hidden;
}
b,
strong {
  font-family: "DINBold";
}
.brown-text {
  color: var(--color-accent-secondary);
}
.green-text {
  color: var(--green) !important;
}
.blue_bg {
  background: var(--color-accent-secondary);
}
.bg_white {
  background: var(--color-white);
}
.grey_bg {
  background: var(--greybg);
}
a {
  text-decoration: none;
  color: var(--color-accent-secondary);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DINBold";
}

.banner-title {
  font-size: 50px;
}

.parallax-bg::after {
  position: absolute;
  content: "";
  background: #000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.4;
}

.ptxt {
  font-size: 14px;
}
.pno {
  font-size: 25px;
}
.sec-padd {
  padding: 80px 0;
}
.sec-padd1{
  /* padding: 50px 0; */
  padding: 80px 0;
}
.sec-padd2{
  /* padding: 10px 0;
  padding-bottom: 0 !important; */
  padding: 80px 0;
}
.sec-padd-top {
  padding-top: 80px;
}

.sec-padd-bot {
  padding-bottom: 80px;
}

.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mt-60 {
  margin-top: 60px;
}
.sec-mar {
  margin: 70px 0;
}

.text-blue {
  color: var(--color-accent-primary);
}

.section-title,
.h2,
h2 {
  position: relative;
  font-size: 27px;
  line-height: 1.4;
  margin-bottom: 20px;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--color-accent-primary);
}
.section-title2 {
  position: relative;
  font-size: 27px;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--color-accent-primary);
  margin-bottom: 20px;
}
.section-title:after,
.section-title2:after {
  position: absolute;
  content: "";
  height: 1px;
  margin-right: 10px;
  transition: all 0.42s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 12rem;
  background-color: var(--green);
  left: 0;
  bottom: -4px;
}
.center-title {
  position: relative;
}
.center-title:after {
  position: absolute;
  content: "";
  height: 1px;
  margin-right: 10px;
  transition: all 0.42s cubic-bezier(0.25, 0.8, 0.25, 1);
  left: 0;
  right: 0;
  margin: auto;
  width: 7rem;
  bottom: -4px;
  background-color: var(--color-accent-secondary);
}
.white_border::after {
  background-color: var(--color-white);
}
.center-title .section-title:after {
  display: none;
}
.sm-text {
  font-size: 16px;
}
.md-text {
  font-size: 21px;
}
.background-video{
  width: 100%;
  height: auto;
}
.amenities{
  margin-top: -8px;
}

/***Header****/
.navbar .nav-link {
  font-family: "DINRegularAlternate";
  font-size: 15px;
  margin-left: 10px !important;
  color: #000;
  font-weight: 600;
}
.navbar {
  transition: ease all 0.5s;
  background: #ffff;
}
.fixed {
  transition: ease all 0.5s;
  background: #ffff;
}

.navbar-brand img {
  max-width: 14vw;
  height: auto;
}
.navbar-nav .dropdown-menu {
  border-radius: 0;
  border: 0;
}
.navbar-nav .dropdown-menu li {
  border-bottom: 1px solid #fff;
}
.dropdown-item {
  font-family: "DINRegularAlternate";
  font-size: 15px;
  color: #000;
  font-weight: 600;
  padding: 0.6rem 0.5rem;
}
.top-social li a {
  padding: 0px 5px;
  font-size: 18px;
}
.top-social li a span {
  font-size: 0;
}
.top-social li a.phone_icon {
  font-size: 15px;
  margin: 0px 8px;
}
.top-social li a.facebook_icon {
  color: #3b5998;
}
.top-social li a.twitter_icon {
  color: #00acee;
}
.top-social li a.insta_icon {
  color: #d43153;
}
.top-social li.whats__app i {
  font-size: 18px;
  color: #1c9e0a;
}
.top-social li .fa-youtube {
  color: red;
}
.top-social li a.phone_icon {
  color: #f79433;
}
.subhead {
  position: relative;
}
/* .subhead:first-child::after {
  content: "";
  width: 100%;
  height: 1px;
  top: 14px;
  border-top: 1px solid var(--color-accent-secondary);
  margin: 0 0 0 30px;
  flex: 1 0 20px;
} */
.subhead:first-child::after {
  margin: 0 0 0 30px;
  content: "";
  display: inline-block;
  height: 1px;
  margin-right: 10px;
  transition: all 0.42s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 12rem;
  background-color: var(--color-accent-secondary);
}
.image-container {
  width: 100%;
}
.image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}
.image-container > div {
  position: unset !important;
}
.map-box {
  display: flex;
  margin-bottom: 10px;
}
.add_wrap {
  width: 80%;
  padding-left: 15px;
}
.map-box .img-wrap {
  width: 20%;
}
.map-box .img-wrap img {
  max-width: 80px;
}
.grid_temp {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
/* button css */
.round-btn {
  background: var(--color-accent-primary);
  color: #fff;
  padding: 10px 15px !important;
  border-radius: 50px;
  font-size: 13px !important;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;
}
.read-more {
  position: relative;
  display: inline-block;
  background: #f79433;
  color: white;
  text-transform: uppercase;
  font-size: 15.65px;
  padding: 9px 0;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
  margin-top: 30px;
  line-height: 13px;
  border: solid 1px #f79433;
  -webkit-transition: ease 0.5s all;
  -moz-transition: ease 0.5s all;
  transition: ease 0.5s all;
  position: relative;
}
.round-btn:hover,
.read-more:hover {
  background: var(--color-accent-secondary);
  color: #fff;
}
.btn {
  position: relative;
  background: var(--color-accent-secondary);
  color: var(--color-white);
  border: 1px solid var(--color-accent-secondary);
  text-transform: uppercase;
  font-size: 1rem;
  font-family: inherit;
  font-weight: 600;
  border-radius: 0;
  min-width: 230px;
  transition: all 1s;
  overflow: hidden;
  z-index: 1;
  padding: 10px 20px;
}
/* .btn::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 0%;
  top: 0;
  left: -40px;
  transform: skewX(45deg);
  background-color: var(--color-white);
  color: #000;
  z-index: -1;

  transition: all 1s;
} */
.btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-accent-primary);
  transform: translateX(-100%);
  transition: all 0.3s;
  z-index: -1;
  color: var(--color-white);
}

.btn:hover::before {
  /* width: 160%; */
  transform: translateX(0);
}
.btn:hover {
  color: var(--color-white);
  border: 1px solid;
}

.btn-round {
  min-width: auto;
  border-radius: 50px;
}
/* button css end */

.project_overview {
  position: relative;
  margin-bottom: -12rem;
}

.read_more_btn a {
  display: inline-block;
  position: relative;
  transition: ease all 0.5s;
}

.read_more_btn a:hover::after {
  margin-left: 15px;
  transition: ease all 0.5s;
}
.swiper-slide-active .subhead {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInLeft;
  animation-delay: 1s;
}
.swiper-slide-active .banner-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 1s;
}
.swiper-nav button span {
  font-size: 0;
}
/* Testimonial  */
.testimonial-wrapper {
  padding-top: 14rem;
}
.testimonial_head {
  position: relative;
}

.grey_patch_section {
  padding: 60px 30px;
  position: relative;
  border-radius: 10px 0 0 10px;
  margin: 50px 15px;
}
.grey_patch_section:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 130%;
  left: 0;
  background: #f9f9f9;
  z-index: -1;
  top: 0;
  border-radius: 10px 0 0 10px;
}
.patch_section_txt_wrap {
  color: #717070;
}
.testimonial-wrapper {
  overflow-x: hidden;
}
.testimonial_title {
  font-size: 30px;
  margin-bottom: 20px;
}
.testimonial-wrapper p {
  font-size: 18px;
}
.reviewer_name {
  font-size: 25px;
}
.cust_hr {
  margin: 1.5rem 0;
  width: 50%;
  color: var(--color-accent-secondary);
}
.direct_img img {
  width: 100%;
  height: auto;
}
.partner-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  gap: 4px;
}
.partner-section .partner-logo-wrapper .logo-box {
  width: 19%;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner-section .partner-logo-wrapper .logo-box img {
  width: 15vw;
  height: 7vw;
  padding: 10px;
}
.logo-box figure {
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}
/* footer section */
.footer_bg {
  position: relative;
  /* background: url("../public/images/footer_bg.webp") no-repeat; */
  background-size: cover;
  background-position: inherit;
}
.footer_bg > * {
  position: relative;
  z-index: 2;
}
.footer-form {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.3);
  padding: 30px 40px;
}
.footer_bg:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    0deg,
    rgba(42, 63, 68, 1) 0%,
    rgba(42, 63, 68, 1) 35%,
    rgba(42, 63, 68, 0.3393732492997199) 100%
  );
  z-index: 1;
}
.quick_links {
  display: flex;
  flex-flow: row wrap;
}
.quick_links li {
  flex: 0 0 40%;
}
.enquire_btn i {
  color: #2a3f44;
}
.links {
  list-style: none;
  padding-left: 0;
}
.links li {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  font-size: 15px;
}
.links li:before {
  content: "";
  display: inline-block;
  height: 1px;
  margin-right: 10px;
  transition: all 0.42s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 0;
  background-color: var(--color-white);
}
.links li:hover:before {
  background-color: var(--color-white);
  width: 0.7rem;
}
.links li a {
  color: var(--color-white);
}
.widget-title {
  margin-bottom: 20px;
}
/* footer section end*/
.floating-soclinks i {
  color: var(--green);
}
.floating-soclinks .hover:hover i {
  color: var(--color-white);
}
.social-links li:hover {
  background: var(--color-accent-secondary);
  cursor: pointer;
}
.social-links {
  display: flex;
  padding-left: 0;
}
.social-links li {
  list-style: none;
  margin: 0 5px;
  height: 30px;
  width: 30px;
  color: var(--color-white);
  border: 1px solid var(--color-white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  transition: all ease 0.3s;
}
.social-links li i {
  color: var(--color-white);
}
.social-links li a span {
  font-size: 0;
}
.floating_wrapper {
  position: absolute;
  right: 15px;
  top: 50%;
  z-index: 9;
}
.floating-soclinks {
  list-style: none;
  position: relative;
}
.floating-soclinks li {
  font-size: 20px;
}
.floating-soclinks li:nth-child(2) {
  margin: 15px 0;
}
/* form css */
.form-control,
.form-control:focus {
  background: transparent;
  color: var(--color-white) !important;
  border: 0;
  border-bottom: 1px solid var(--color-white) !important;
  border-radius: 0;
  box-shadow: none;
  font-size: 1rem;
  font-family: "DINRegularAlternate";
}
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right 0.75rem center !important;
  background-size: 16px 12px !important;
}
.form-control::placeholder {
  color: var(--color-white);
}
#project option {
  color: var(--color-accent-secondary);
}
.enquiry-form {
  background: var(--color-accent-primary);
  padding: 10px 0;
  top: calc(100% - 90.5vh);
  z-index: 2;
}
/* .form-group {
  height: 6vh;
} */
.error {
  color: #ff0000;
  font-size: 12px;
}
.enquire_float {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 9;
}
.enquire_btn {
  position: relative;
  width: 50px;
  height: 50px;
  background: var(--color-white);
  border-radius: 50%;
  z-index: 9;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.enquire_btn .enq-ttip {
  position: absolute;
  font-size: 11px;
  color: #ffffff;
  background: #222222;
  padding: 5px 10px;
  top: 15px;
  right: 125%;
  white-space: nowrap;
  letter-spacing: 1px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s ease;
}
.enquire_btn .enq-ttip:before {
  content: "";
  position: absolute;
  top: 9px;
  right: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent #222222;
}
.enquire_btn:hover .enq-ttip {
  right: 115%;
  opacity: 1;
  visibility: visible;
  transition: all 0.6s ease;
}

.pulseanimation {
  animation: pulse-cust 2s infinite;
}

.pulseanimation:hover {
  animation: none;
}

@-webkit-keyframes pulse-cust {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 66, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse-cust {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/* footer section end*/

/* inner page */
.inner-banner {
  position: relative;
  background-size: cover;
}
.banner-wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  padding: 0 15px;
  text-align: center;
}
.inner-banner::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  z-index: 1;
  opacity: 0.7;
}
.overlaybg::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  z-index: 1;
  opacity: 0.7;
}
.inner-banner.lagacy-banner::after {
  opacity: 0.4 !important;
}
.inner-banner.proj-banner::after {
  opacity: 0;
}
.inner-banner {
  height: 70vh;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-banner .banner-title {
  position: relative;
  z-index: 9;
  text-transform: uppercase;
  font-weight: 800;
}
.breadcrumb-item a {
  color: var(--color-accent-primary) !important;
}
.breadcrumb-item.active a {
  color: var(--color-accent-secondary) !important;
}
.direct_img {
  position: relative;
  display: inline-block;
}

.proj_wrap {
  padding: 20px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.proj_detail {
  list-style: none;
  padding: 0;
}
.proj_detail li {
  display: flex !important;
  margin-top: 4px;
}
.proj_detail li span {
  flex: 0 0 50%;
  font-size: 17px;
}
.project-wrapper {
  display: flex;
  align-items: center;
  gap: 25px;
}
.proj_title {
  font-size: 25px;
  text-transform: uppercase;
}
.proj_loc {
  font-size: 14px;
  font-family: "BeVietnamPro-Medium";
}
.proj_conf {
  font-size: 16px;
  font-family: "BeVietnamPro-Light";
  border-top: 1px solid #2a3f44;
  padding-top: 5px;
  margin-top: 5px;
}
.project-wrapper .proj_img {
  overflow: hidden;
  position: relative;
}
.project-wrapper .proj_img img {
  width: 100%;
  height: auto;
  display: block;
  transform: scale(1);
  transition: ease all 0.5s;
}
.project-wrapper:hover .proj_img img {
  transform: scale(1.1);
  transition: ease all 0.5s;
}
.flat-btn {
  border: 1px solid var(--grey);
  padding: 7px 20px;
  color: var(--grey);
  font-size: 14px;
  display: inline-block;
  margin-top: 5px;
  min-width: auto;
  width: auto;
  background: transparent;
}
.flat-btn:hover {
  color: var(--color-white) !important;
}
.typologies .flat-btn,
.description .flat-btn {
  min-width: auto;
}
.proj_item {
  position: relative;
  display: inline-block;
  padding-right: 5px;
  margin-right: 5px;
  margin-bottom: 15px;
}
.proj_item:last-child {
  padding-right: 0;
  margin-right: 0;
}
.proj_item:after {
  position: absolute;
  content: "";
  border-right: 1px solid var(--color-accent-primary);
  right: 0;
  top: 0;
  height: 100%;
}
.proj_item:last-child:after {
  height: 0;
}
.proj_item img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.flat-btn i {
  font-size: 16px;
  margin-right: 6px;
}
.proj_logo {
  max-width: 232px;
  margin-bottom: 20px;
}
.press img {
  height: 340px !important;
  object-fit: cover;
}
.press,
.water_mark {
  background: url("../public/images/water_mark.png") no-repeat center;
  width: 100%;
  height: 100%;
  background-size: 50%;
  background-position: bottom;
}
.blue_patch {
  background: url("../public/images/blue_patch.jpg") no-repeat center;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.good-living-section {
  text-align: center;
  position: relative;
}
.good-living-section .good-living-slider .living-detail {
  position: absolute;
  left: 0;
  bottom: -100%;
  background: rgba(255, 255, 255, 0.9);
  padding: 25px 0;
  width: 100%;
  -webkit-transition: ease 1s all;
  -moz-transition: ease 1s all;
  transition: ease 1s all;
}
.good-living-section:hover .living-detail {
  bottom: 0;
}
.location-section .map-section .location-box .heading02 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}
.map-section {
  position: relative;
}
.location-section .map-section .location-box .heading02:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: white;
  left: 0;
  bottom: 0;
}
.location-section .map-section .location-box {
  background: rgba(247, 148, 51, 0.9);
  padding: 35px;
  position: absolute;
  width: 410px;
  top: 50%;
  -webkit-transform: translate(95px, -50%);
  -moz-transform: translate(95px, -50%);
  -ms-transform: translate(95px, -50%);
  -o-transform: translate(95px, -50%);
  transform: translate(95px, -50%);
  color: white;
  text-align: left;
}
.location-section .map-section .map-bx iframe {
  width: 100%;
  height: 500px;
}
.benfit-wrap .icon_box p {
  color: var(--color-accent-secondary);
}

.loc_list li {
  font-size: 15px;
  margin-bottom: 8px;
  position: relative;
  padding-left: 20px;
}
.loc_list li::before {
  position: absolute;
  content: "";
  background: url("/images/pin.png");
  width: 16px;
  height: 16px;
  left: 0;
  top: 5px;
  background-size: contain;
  background-repeat: no-repeat;
}
/* accordian */
section .location_wrapper .accordion-button {
  background: transparent;
  border-radius: 0;
  background-image: none;
  box-shadow: 0 0 0 0;
  color: var(--color-accent-secondary);
}
section .location_wrapper .accordion-item {
  margin-bottom: 0;
  border-bottom: 1px solid #ddd !important;
  border-top: 0 !important;
}
section .location_wrapper .accordion-item:first-of-type,
section .location_wrapper .accordion-item:last-of-type .accordion-collapse,
section
  .location_wrapper
  .accordion-item:last-of-type
  .accordion-button.collapsed,
section .location_wrapper .accordion-item:last-of-type {
  border-radius: 0 !important;
}
section .location_wrapper .accordion-body {
  border: 0;
}
section .location_wrapper .accordion-body p:last-child {
  margin-bottom: 0;
}
section .location_wrapper .accordion-button::after {
  filter: none;
  background-image: url("data:image/svg+xml,%3Csvg fill='%23000000' width='500px' height='500px' viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.125 7.5h3.438a0.313 0.313 0 1 1 0 0.625H8.125v3.438a0.313 0.313 0 1 1 -0.625 0V8.125H4.063a0.313 0.313 0 1 1 0 -0.625H7.5V4.063a0.313 0.313 0 1 1 0.625 0V7.5Z'/%3E%3C/svg%3E");
}
section .location_wrapper .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3C!-- License: PD. Made by linea-io: https://github.com/linea-io/Linea-Iconset --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='800px' height='800px' viewBox='0 0 64 64' enable-background='new 0 0 64 64' xml:space='preserve'%3E%3Cg%3E%3Cline fill='none' stroke='%23000000' stroke-width='2' stroke-miterlimit='10' x1='14' y1='31' x2='50' y2='31'/%3E%3C/g%3E%3C/svg%3E");
}
.accordion-button::after {
  filter: brightness(0) invert(1);
}
.faqs_accord .accordion-item .accordion-button {
  background-color: transparent;
  box-shadow: none;
}
.accordion-button {
  color: var(--color-white);
  background-color: var(--color-accent-secondary);
  font-family: "DINRegularAlternate";
  font-size: 18px;
}
.accordion-button:not(.collapsed) {
  background-color: var(--color-accent-secondary);
  color: var(--color-white);
}
.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}
.accordion-item {
  border-radius: 0;
  border: none;
  border-top: 1px solid #dddddd !important;
  margin-bottom: 20px;
}
.accordion-body {
  background: #f7f7f7 !important;
  border: 1px solid var(--color-accent-secondary);
  border-top: 0;
}
.accord_list {
  list-style: none;
}
.accord_list li {
  position: relative;
  margin-bottom: 8px;
  padding-left: 7px;
}
.accord_list li::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  left: -19px;
  top: 3px;
  background-image: url("data:image/svg+xml,%3Csvg height='32px' width='32px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2 2' enable-background='new 0 0 50 50' xml:space='preserve'%3E%3Cpath fill='%23171c44' d='M0.623 1.633a0.04 0.04 0 0 0 0.057 0l0.6 -0.6a0.04 0.04 0 0 0 0 -0.057l-0.6 -0.6a0.04 0.04 0 1 0 -0.057 0.057l0.572 0.572 -0.572 0.572a0.04 0.04 0 0 0 0 0.057z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
}
.accord_list li:last-child {
  margin-bottom: 0;
}
.loc_title {
  font-size: 21px;
  margin-bottom: 2px;
}
.footer-links h4 {
  margin-left: 10px;
  font-weight: 400 !important;
  border-bottom: 1px solid #fff;
  display: inline-block;
  line-height: 1.8;
}
.rera_disc img {
  max-width: 40px;
}
.popup_btn {
  cursor: pointer;
}

.popup_btn:hover {
  color: var(--green);
}
.fa-indian-rupee-sign {
  font-size: 14px;
}
.faq-heading {
  font-family: "DINRegularAlternate";
  font-size: 20px;
  margin: 15px 0;
  color: var(--color-accent-secondary);
}

.project-wrap {
  margin-left: 30px;
}
.proj-loc i {
  width: 24px;
  text-align: center;
  font-size: 13px;
}
.proj-loc .fa {
  color: var(--color-accent-secondary);
}
.carpet-area {
  display: inline-block;
}
.border-tb {
  border-top: 1px solid var(--color-accent-secondary);
  border-bottom: 1px solid var(--color-accent-secondary);
  padding: 15px 0 !important;
}
.btn-wrapper {
  margin-top: 30px;
}
.project-image,
.project-image-wrapper,
.read-more-wrap {
  position: relative;
}
.image-wrap img {
  position: relative;
  z-index: 1;
}
.proj-title {
  position: absolute;
  bottom: 30px;
  left: -30px;
  background: var(--color-accent-secondary);
  font-size: 18px;
  color: #fff;
  padding: 6px;
  font-weight: 600;
  z-index: 1;
}
.bord-effect {
  position: absolute;
  content: "";
  border: 1px solid var(--color-accent-secondary);
  width: 100%;
  height: 100%;
  top: -20px;
  right: -20px;
}
.project-content {
  padding-left: 30px;
}
.read-more-wrap .butn-dark {
  padding-left: 15px;
  background: #fff;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.bl-effect {
  position: absolute;
  content: "";
  border-top: 1px solid var(--color-accent-secondary);
  width: 100%;
  height: 100%;
  top: 50%;
  left: 0;
}
.project-wrap .row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.proj-loc img {
  max-width: 22px;
  height: auto;
  top: 5px;
  position: relative;
}
.project-content p {
  margin-bottom: 15px;
}
.text-right {
  text-align: right !important;
}
/* Tabs */
.tabs .nav-item button {
  border: 0;
  background: transparent;
  padding: 0.2rem 0;
  margin: 0 0.6rem;
  color: var(--color-accent-primary);
  font-size: 18px;
}
.tabs .nav-item button.active {
  border-bottom: 1px solid var(--color-accent-secondary);
  font-weight: 600;
}
.nav-pills .nav-link {
  color: var(--color-accent-secondary);
  background: transparent;
  border: 1px solid var(--color-accent-secondary);
  border-radius: 0;
  min-width: 195px;
  margin: 0 15px 15px !important;
  font-weight: 600;
}
.nav-pills .nav-link.active {
  background: var(--color-accent-secondary);
  color: var(--color-white);
  border: 1px solid var(--color-accent-secondary);
}
.nav-pills .nav-item {
  margin-bottom: 20px;
}
.tab_ver2.nav-pills .nav-item {
  margin: 0;
}
.tab_ver2.nav-pills .nav-link {
  margin-bottom: 0 !important;
}
.tab_border .tab-content {
  border: 1px solid #173e62;
}
.conf_detail ul li > span {
  width: 130px;
  display: inline-block;
}
.floor_box {
  padding: 15px 0;
}
.conf_detail {
  padding-left: 15px;
}
.floorplan .tab-pane .img-wrap {
  border: 1px solid #173e62;
  padding: 20px;
}
.icon_box img {
  height: 60px !important;
  object-fit: contain;
}
.icon_box {
  text-align: center;
}
.icon_box p {
  color: var(--color-white);
}
.loc_disc {
  font-size: 18px;
}
.loc_disc i {
  width: 20px;
}
.contactus .loc_disc a {
  color: var(--color-accent-primary);
}
.contactus .loc_disc a:hover {
  color: var(--green);
}
.gal_txt {
  font-size: 22px;
  text-align: center;
  margin-top: 20px;
  color: var(--color-accent-primary);
  font-family: "DINBold";
}
.gal_conf {
  font-size: 18px;
}
.gal_price {
  font-size: 20px;
}
.gallery .gall_wrp img {
  height: 340px !important;
  object-fit: cover !important;
}
.gall_wrp {
  position: relative;
}
.gall_wrp .img_overlay {
  position: relative;
}
.gall_wrp .img_overlay::after {
  position: absolute;
  content: "";
  background: #0606067a;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
}
.videothumb img {
  height: 700px !important;
}
.video .img_overlay::before {
  position: absolute;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 60 60' style='enable-background:new 0 0 294.843 294.843;' xml:space='preserve' width='60' height='60'%3E%3Cg%3E%3Cpath fill='%23fff' d='M56.68 16.269c-2.101 -4.075 -5.165 -7.673 -8.86 -10.405c-0.542 -0.401 -1.307 -0.286 -1.708 0.256s-0.286 1.307 0.256 1.708c3.396 2.511 6.211 5.817 8.142 9.561c2.023 3.923 3.048 8.166 3.048 12.612c0 15.195 -12.363 27.558 -27.558 27.558S2.442 45.196 2.442 30S14.804 2.442 30 2.442c0.674 0 1.221 -0.547 1.221 -1.221s-0.547 -1.221 -1.221 -1.221C13.458 0 0 13.458 0 30s13.458 30 30 30s30 -13.458 30 -30C60 25.229 58.852 20.481 56.68 16.269z'/%3E%3Cpath fill='%23fff' d='M22.324 16.07c-0.382 0.217 -0.618 0.623 -0.618 1.061v26.795c0 0.674 0.547 1.221 1.221 1.221s1.221 -0.547 1.221 -1.221V19.279l18.077 10.762l-13.293 8.565c-0.567 0.365 -0.73 1.121 -0.365 1.688c0.365 0.567 1.121 0.731 1.688 0.365l14.95 -9.632c0.355 -0.229 0.567 -0.625 0.559 -1.048c-0.008 -0.423 -0.233 -0.811 -0.596 -1.028L23.551 16.082C23.174 15.858 22.705 15.853 22.324 16.07z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: contain;
  width: 50px;
  height: 50px;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  animation: pulse-cust 2s infinite;
  border-radius: 50%;
  z-index: 9;
}
.video .gall_wrp .img_overlay::after {
  opacity: 1;
}
.gall_wrp .img_overlay:hover:after {
  opacity: 1;
}
.gall_over {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.gall_over:after {
  content: "";
  display: inline-block;
  transition: all 0.42s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: absolute;
  top: 0;
  left: 13rem;
  color: #fff;
  background-color: var(--color-white);
  width: 0;
  height: 0;
}
.gall_over:before {
  content: "";
  display: inline-block;
  transition: all 0.42s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: absolute;
  top: 13rem;
  left: 0;
  color: #fff;
  background-color: var(--color-white);
  width: 0;
  height: 0;
}
.gall_wrp:hover .gall_over:after {
  left: -0.8rem;
  width: 2rem;
  height: 2px;
}
.gall_wrp:hover .gall_over:before {
  top: -0.8rem;
  width: 2px;
  height: 2rem;
}
.gall_wrp:hover {
  cursor: pointer;
}
.marketing-list {
  list-style: none;
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
}
.marketing-list span {
  font-family: "DINBold";
  margin-right: 10px;
}
/* contact form */
:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.contactpage-form .row,
.popupform .row {
  flex-direction: column;
}
.contactpage-form .row > *,
.popupform .row > * {
  width: 100%;
  margin-bottom: 20px !important;
  height: 58px;
}
.footer-form .row > * {
  width: 100%;
  margin-bottom: 20px !important;
  height: 55px;
}
.contactpage-form .form-control,
.contactpage-form .form-control:focus,
.popupform .form-control,
.popupform .form-control:focus,
.darkform .form-control,
.darkform .form-control:focus {
  color: var(--color-accent-secondary) !important;
  border: 1px solid var(--color-accent-secondary);
  border-bottom: 1px solid var(--color-accent-secondary) !important;
  height: calc(2.8rem + 2px);
  line-height: 1.25;
}
.darkform .row > * {
  margin-bottom: 20px !important;
  height: 58px;
}
.contactpage-form .form-control::placeholder,
.popupform .form-control::placeholder,
.darkform .form-control::placeholder {
  color: var(--color-accent-secondary);
}
.contactpage-form .submit-btn,
.popupform .submit-btn,
.darkform .submit-btn {
  text-align: center !important;
  margin-bottom: 0 !important;
}
.contactpage-form,
.darkform-form {
  background: #f3f3f3 !important;
}
.facility-section .facility-wrapper {
  position: relative;
}
.facility-section .position-relative {
  overflow: hidden;
}
.facility-section .position-relative .img-wrap img {
  transform: scale(1);
  transition: ease all 0.5s;
}
.facility-section .position-relative:hover .img-wrap img {
  transform: scale(1.1);
  transition: ease all 0.5s;
}
.facility-section .facility-wrapper .facility-details-wrap {
  background: rgba(255, 255, 255, 0.95);
  position: absolute;
  top: 0;
  width: 37%;
  height: 100%;
  padding: 25px 15px;
  left: -100%;
  transition: ease 1s all;
  overflow: hidden;
}
.facility-section .facility-wrapper .active .facility-details-wrap {
  left: 0;
  animation: myAnim 1s ease 0s 1 normal forwards;
}
@keyframes myAnim {
  0% {
    opacity: 0;
    transform: translateX(-250px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.mCSB_container {
  height: 650px;
  overflow-y: scroll;
}
.mCSB_container::-webkit-scrollbar {
  box-shadow: inset 0 0 6px var(--color-accent-primary);
  width: 3px;
}

.mCSB_container::-webkit-scrollbar-track {
  background-color: var(--color-accent-primary);
  border-radius: 100px;
}

.mCSB_container::-webkit-scrollbar-thumb {
  background-color: var(--color-accent-secondary);
  border-radius: 100px;
}
.amenities-icon img {
  width: 50px !important;
}
.amenities-list {
  list-style: none;
}
.amenities-list-box {
  display: flex;
  margin-top: 18px;
  align-items: center;
}

.specification-section .specification-box .specification-heading .space-title {
  position: relative;
  padding-bottom: 20px;
  display: inline-block;
  vertical-align: middle;
  padding-right: 20px;
  margin-right: 20px;
}
.specification-section
  .specification-box
  .specification-heading
  .space-title:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: #f79433;
  left: 0;
  bottom: 0;
}
.specification-section .specification-box .specification-heading .spec-img {
  display: inline-block;
  vertical-align: middle;
}
.heading02 {
  font-size: 27px;
  font-family: "DINBold";
  line-height: 20px;
}
.specification-section .specification-box .specification-heading {
  margin-bottom: 20px;
}
.specification-section .specification-box {
  width: 100%;
  margin-top: 50px;
}
.ul-list li {
  position: relative;
  margin-bottom: 15px;
  font-size: 15.65px;
  line-height: 20px;
}

/* whatsapp form */
.whatsapp_box {
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 3rem 1rem 2rem;
  border-radius: 10px;
  margin-bottom: 2rem;
}
.whatsapp_box:before {
  content: "";
  position: absolute;
  top: -150px;
  right: -19px;
  width: 86px;
  height: 187px;
  background: url(/images/arrow.png) no-repeat center;
  transform: scaleX(-1);
}
.whatsapp_popup .modal-content {
  background: url(/images/popupbg.jpg) no-repeat center;
  background-size: cover;
}
.whatsapp_popup .form-control,
.whatsapp_popup .form-control:focus {
  color: var(--color-white) !important;
  border: 1px solid var(--color-white);
  border-bottom: 1px solid var(--color-white) !important;
}
.whatsapp_popup .form-switch label {
  color: var(--color-white);
}
.whatsapp_popup .form-control::placeholder {
  color: var(--color-white);
}
.whatsapp_popup .termCondition,
.whatsapp_popup .termCondition a,
.whatsapp_popup .termCondition span {
  color: var(--color-white) !important;
}
.whatsapp_popup .checkwrp .form-switch::before {
  color: var(--color-white);
}
.enquiry-form .form-switch label {
  color: var(--color-white);
  font-size: 14px;
}
.enquiry-form .checkwrp {
  display: flex;
  align-items: center;
}
body .modal-body form .checkwrp,
.footer-form form .checkwrp {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;
}
.checkwrp > *,
.termCondition {
  font-size: 12px !important;
  line-height: 1.4 !important;
}
.checkwrp .form-switch {
  position: relative;
  padding-left: 1.5em;
}
.checkwrp .form-switch::before {
  position: absolute;
  content: "\f232";
  font-family: "Font Awesome 6 Brands";
  -moz-osx-font-smoothing: grayscale;
  webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  font-weight: 400;
  left: 0;
  font-size: 15px;
}
.termCondition a,
.termCondition span {
  color: var(--bs-link-color) !important;
}

/* whatsapp form end*/

/* /popup form/ */
.modal-body h3 {
  text-align: center;
  font-size: 20px;
}
.modal-body p {
  font-size: 14px;
}
.modal {
  background: rgba(0, 0, 0, 0.7);
}

.building_zone .details p {
  font-family: "Montserrat";
  font-size: 20px;
  line-height: 32px;
  color: #454545;
  text-align: center;
  margin-bottom: 20px;
}
.payment-schedule {
  margin-top: 25px;
  height: 75vh;
  overflow-y: scroll;
}
.payment-schedule .inner-payment-schedule {
  border: solid 1px #161b44;
}
.payment-schedule li p {
  display: inline-block;
  margin: 0;
  margin-right: -4px;
  padding: 5px 20px;
  vertical-align: top;
}
.payment-schedule li {
  border-bottom: solid 1px #161b44;
}
.payment-schedule li p:first-child {
  width: 60%;
}
.payment-schedule li p:nth-child(2) {
  width: 40%;
  border-left: solid 1px #161b44;
}

.residency-wrapper.gallery-wrapper .gallery-box {
  margin-left: 20px;
  width: -webkit-calc(50% - 24px);
  width: -moz-calc(50% - 24px);
  width: -ms-calc(50% - 24px);
  width: calc(50% - 24px);
  position: relative;
  margin-bottom: 20px;
  display: inline-block;
  vertical-align: top;
}
.residency-wrapper .residency-box .residency-img a{
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
}
.residency-wrapper.gallery-wrapper .gallery-box .residency-img .plus-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.residency-wrapper .residency-box .residency-img .plus-icon {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  text-align: center;
  cursor: pointer;
  z-index: 1;
}
.residency-wrapper.gallery-wrapper .gallery-box .gallery-title {
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 5px;
  text-align: center;
  font-family: "DINBold";
  background: #ebebeb;
}
.btnheading {
  font-size: 22px;
  font-family: "DINBold";
  border: 0;
  background: var(--color-accent-primary);
  color: var(--color-white);
  width: 100%;
  padding: 7px 15px;
}

/* press */
.press-release-list {
  margin-top: 35px;
  margin-left: 0;
  padding: 0;
}
.press-release-list li {
  width: -webkit-calc(50% - 39px);
  width: -moz-calc(50% - 39px);
  width: -ms-calc(50% - 39px);
  width: calc(50% - 39px);
  display: inline-block;
  margin-left: 35px;
  border: solid 1px #161b44;
  margin-bottom: 35px;
}
.press-release-list li .press-img {
  width: 40%;
  display: inline-block;
  vertical-align: middle;
  margin-right: -4px;
}
.press-release-list li .press-img img {
  display: block;
}
.press-release-list li .press-details {
  width: 60%;
  display: inline-block;
  margin-right: -4px;
  vertical-align: middle;
  padding: 20px 25px;
  text-align: left;
}
.press-release-list li .press-details h4 {
  font-weight: bold;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.press-release-list li .press-details .press-category {
  text-transform: uppercase;
  margin-top: 8px;
  display: block;
  font-size: 15px;
}
.press-release-list li .press-details .date-time {
  margin-top: 12px;
}
.press-release-list li .press-details .date-time i {
  font-size: 20px;
  vertical-align: middle;
}
.press-release-list li .press-details .date-time span {
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
}
.press-release-list li .press-details p {
  margin-top: 10px;
  line-height: 20px;
}
.press-release-list li .press-details .read-more {
  width: auto;
  padding: 5px 15px;
  margin-top: 0;
}

/* blog */
/*********Blog*******/
.blog_wrap {
  margin-bottom: 30px;
}
.cat-item a {
  color: #222;
}
.blog_date {
  color: #888;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
}
.blog_img {
  position: relative;
  overflow: hidden;
}
.blog_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transform: scale(1);
  transition: ease all 0.5s;
  object-position: top right;
}
.blog_date img {
  width: 20px !important;
  margin-right: 5px;
}
.blog_tittle {
  font-size: 20px;
}
.blog_tittle a:hover {
  color: var(--color-accent-primary);
}
.two_column_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin: 0 auto;
}
.blog_img:hover img {
  transform: scale(1.2);
  transition: ease all 0.5s;
}
.single a {
  color: #ec6f24;
}
.widget-title {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
  word-break: break-word;
  z-index: 1;
}
.widget-title:first-child::after {
  content: "";
  width: 100%;
  height: 1px;
  top: 14px;
  border-top: 1px solid var(--color-accent-primary-1);
  margin: 0 0 0 30px;
  flex: 1 0 20px;
}
.post_img {
  margin-bottom: 20px;
}
.post_detail {
  margin-bottom: 40px;
}
.post_detail ul {
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}
.post_detail ul li {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.post_detail ul li:last-child {
  margin-right: 0;
}
.post_detail ul li img {
  width: 20px;
  margin-right: 7px;
}
.pagination {
  justify-content: center;
  margin-bottom: 0;
  margin-top: 20px;
}

.pagination .page-item {
  background: #ededed;
}

.pagination .page-item .page-numbers {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222;
}

.pagination .page-item:first-child {
  background: rgba(0, 0, 0, 0);
}

.pagination .page-item:first-child a {
  color: #222;
  font-size: 24px;
}

.pagination .page-item:last-child {
  background: rgba(0, 0, 0, 0);
}

.pagination .page-item:last-child a {
  color: #222;
  font-size: 24px;
}

.pagination .page-item.active {
  background: var(--color-accent-secondary);
}

.pagination .page-item.active a {
  color: #fff;
}
/* .mobilelivesquare img {
  right: 2% !important;
  width: 50px !important;
  height: 50px !important;
} */
.whatsfixed {
  position: fixed;
  bottom: 20px;
  right: 2%;
  z-index: 99;
}
.whatsapp-wrap {
  text-align: right;
}
.whatsfixed p,
.whatsfixed a {
  font-size: 13px;
}
.messageWrapperBox {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  background-color: #fff;
  padding: 14px 20px 10px 10px;
}
.closebtn_wrp {
  position: absolute;
  right: 0px;
  top: -3px;
  padding: 0 5px;
}
.closebtn {
  all: unset;
}
.closebtn i {
  font-size: 12px;
}

/* marquee */
@-webkit-keyframes marquee {
  0% {
    margin-left: 100%;
    transform: translateX(0%);
  }
  100% {
    margin-left: 0;
    transform: translateX(-100%);
  }
}
@keyframes marquee {
  0% {
    margin-left: 100%;
    transform: translateX(0%);
  }
  100% {
    margin-left: 0;
    transform: translateX(-100%);
  }
}

.marquee_sec .marquee {
  -webkit-animation: marquee 20s linear infinite;
  animation: marquee 20s linear infinite;
  display: inline-block;
  white-space: nowrap;
}
.marquee_sec .marquee:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.marquee_sec {
  overflow: hidden;
  background-color: lightblue;
  padding: 0.5rem;
  font-size: 18px;
}

/* thank you */
.oops-greet {
  color: #222;
  font-size: 25px;
  margin-bottom: 15px;
  text-align: center;
}
.oops-subtitle {
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 30px;
  text-transform: capitalize;
  text-align: center;
}
.msgicon {
  font-size: 52px;
  text-align: center;
}
@media only screen and (max-width: 1600px) {
  .videothumb img {
    height: 556px !important;
  }
  .whatsfixed img {
    width: 55px;
    height: 55px;
  }
}
@media only screen and (max-width: 1023px) {
  .press-release-list {
    margin-left: -15px;
  }
  .press-release-list li {
    width: -webkit-calc(50% - 19px);
    width: -moz-calc(50% - 19px);
    width: -ms-calc(50% - 19px);
    width: calc(50% - 19px);
    margin-left: 15px;
    margin-bottom: 15px;
  }
  .press-release-list li .press-details {
    padding: 15px;
  }
}

@media only screen and (min-width: 991px) {
  .nav-item.dropdown:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-link {
    position: relative;
    /* transition: padding 0.5s cubic-bezier(0.77, 0, 0.175, 1) 0s,
      color 1s cubic-bezier(0.77, 0, 0.175, 1) 0s; */
    display: inline-block;
  }

  .navbar .nav-link:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    background-color: var(--color-white);
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  }
  .navbar .nav-item:hover > a:before {
    width: 100%;
  }
  .navbar .nav-item:hover > a {
    color: #000;
  }
  .navbar .nav-link.active:before {
    width: 100%;
  }
  .navbar .nav-link:before {
    width: 0;
  }
  .dropdown-toggle:hover .dropdown-menu {
    display: block !important;
  }
}
@media only screen and (max-width: 1300px) {
  .sec-padd {
    padding: 50px 0;
  }
  .sec-padd-top {
    padding-top: 50px;
  }
  .sec-padd-bot {
    padding-bottom: 50px;
  }
  .project_overview {
    margin-bottom: -7rem;
  }
  .section-title,
  .h2,
  h2,
  .section-title2 {
    font-size: 26px;
  }
  .heading02 {
    font-size: 24px;
  }
  .testimonial-wrapper p {
    font-size: 15px;
  }
  .accordion-button {
    font-size: 17px;
  }
  .proj_logo {
    max-width: 174px;
  }
  .proj_item .text_wrp {
    font-size: 11px;
  }
  .icon_box img {
    height: 50px !important;
  }
  .page-loader [data-splitting] {
    font-size: 20vw;
  }
  .navbar-brand img {
    height: auto;
  }
  .navbar .nav-link {
    font-size: 12px;
    margin-left: 10px !important;
  }
  .dropdown-item {
    font-family: "DINRegularAlternate";
    font-size: 12px;
    color: #000;
    font-weight: 600;
  }
  .desc {
    font-size: 11px;
  }
  .round-btn {
    font-size: 9px !important;
    padding: 7px 15px !important;
  }
  .enquiry-form {
    top: calc(100% - 87vh);
  }
  .btn {
    font-size: 0.8rem;
    min-width: 183px;
    padding: 6px 12px;
  }
  .form-control,
  .form-control:focus {
    font-size: 0.7rem;
  }
  .faq-heading {
    font-size: 17px;
  }
  .residency-wrapper.gallery-wrapper .gallery-box .gallery-title {
    font-size: 16px;
    padding: 10px 5px;
  }
  .contactpage-form .form-control,
  .contactpage-form .form-control:focus,
  .popupform .form-control,
  .popupform .form-control:focus,
  .darkform .form-control,
  .darkform .form-control:focus {
    height: calc(2rem + 2px);
    line-height: 1.25;
  }
  .contactpage-form .row > *,
  .popupform .row > * {
    height: 45px;
  }
  .videothumb img {
    height: 463px !important;
  }
  .loc_list li {
    font-size: 13px;
  }
  .links li {
    font-size: 13px;
  }
  .foo-rera p,
  .whatsapp_box p {
    font-size: 13px;
  }
  .whatsapp_box:before {
    top: -71px;
    right: -1px;
    width: 41px;
    height: 117px;
    background-size: contain;
  }
  .whatsfixed p,
  .whatsfixed a {
    font-size: 11px;
  }
  .marquee_sec {
    font-size: 13px;
    padding: 0.3rem;
  }
}
@media only screen and (max-width: 1200px) {
  .btn {
    min-width: 130px;
  }
  .section-title,
  .h2,
  h2,
  .section-title2 {
    font-size: 22px;
  }
  .project_overview {
    margin-bottom: 0;
  }
  .testimonial-wrapper {
    padding-top: 30px;
  }
  .testimonial_title {
    font-size: 27px;
    margin-bottom: 20px;
  }
  .testimonial-wrapper p {
    font-size: 16px;
  }
  .heading02 {
    font-size: 22px;
  }
  .videothumb img {
    height: 397px !important;
  }
}
@media only screen and (max-width: 991px) {
  .sec-padd {
    padding: 30px 0;
  }

  .sec-padd-top {
    padding-top: 30px;
  }
  .sec-padd-bot {
    padding-bottom: 30px;
  }
  .sec-mar {
    margin: 30px 0;
  }
  .mt-60 {
    margin-top: 30px;
  }
  .navbar .nav-link,
  .navbar-nav .nav-link.active {
    font-size: 15px;
    border-bottom: 1px solid #fff;
    padding: 10px 0px !important;
    margin: 0 20px;
    color: #fff;
  }
  .navbar-nav .dropdown-menu {
    border-radius: 0;
    border: 0;
    background: var(--color-accent-primary);
    margin: 0 20px;
  }
  .dropdown-item {
    font-size: 15px;
    color: #fff;
  }
  .navbar .nav-item:last-child .nav-link {
    border-bottom: 0;
  }
  .banner-title {
    font-size: 40px !important;
  }
  .pno {
    font-size: 20px;
  }
  .section-title,
  .h2,
  h2,
  .section-title2 {
    font-size: 28px;
  }
  .navbar-collapse {
    background: var(--color-accent-primary);
    margin-top: 13px;
  }

  .navbar-toggler,
  .navbar-toggler:focus {
    border: none;
    box-shadow: none;
  }

  .icon-wrp img {
    max-width: 40px;
  }
  .cont-btn img {
    max-width: 57px;
  }
  .testimonial_head::after {
    width: 74px;
    height: 65px;
    top: 4px;
  }
  .patch_section_img_wrap img {
    width: 100% !important;
    height: auto;
  }
  .reviewer_name {
    font-size: 22px;
  }
  .floating_wrapper {
    display: none;
  }
  .footer-form {
    padding: 23px 20px;
  }

  .direct_img::after {
    width: 74px;
    height: 65px;
  }
  .col-reverse {
    flex-direction: column-reverse;
  }

  .inner-banner {
    height: 45vh;
  }
  .loc_title {
    font-size: 18px;
  }
  .loc_disc {
    font-size: 13px;
  }
  .gallery .nav-item {
    margin-bottom: 15px;
  }
  .tabs .nav-item button {
    font-size: 16px;
  }
  .gallery .gall_wrp img {
    height: 240px !important;
  }
  .round-btn {
    padding: 10px 10px !important;
    font-size: 9px !important;
  }
  .enquiry-form {
    position: relative !important;
    z-index: 1;
    top: 0;
  }
  .nav-pills .nav-link {
    min-width: 132px;
    margin: 0 7px 15px !important;
  }
  .gal_txt {
    font-size: 20px;
  }
  .gal_price {
    font-size: 18px;
  }
  .good-living-section .good-living-slider .living-detail {
    position: relative;
    bottom: 0;
  }
  .partner-section .partner-logo-wrapper .logo-box {
    width: 25%;
  }
  .project-content {
    padding-left: 0;
    padding-top: 25px;
  }
  .bord-effect {
    top: -15px;
    right: -15px;
  }
  .project-wrap {
    margin-left: 15px;
    margin-right: 15px;
  }
  .proj-title {
    left: -17px;
    font-size: 15px;
    bottom: 15px;
  }
  .project-content p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .section-title,
  .h2,
  h2,
  .section-title2 {
    font-size: 22px;
  }
  .md-text {
    font-size: 16px;
  }
  .banner-title {
    font-size: 27px !important;
  }
  .heading02 {
    font-size: 20px;
  }

  .testimonial_head::after {
    width: 60px;
    height: 42px;
    top: 16px;
  }
  .partner-section .partner-logo-wrapper .logo-box {
    width: 49%;
  }
  .partner-section .partner-logo-wrapper .logo-box img {
    width: 60vw;
    height: 16vw;
  }
  .grey_patch_section {
    padding: 30px 15px;
    margin: 30px 15px;
  }
  .testimonial-wrapper p {
    font-size: 14px;
  }
  .testimonial_title {
    font-size: 24px;
  }
  .reviewer_name {
    font-size: 20px;
  }
  .enquire_btn {
    width: 40px;
    height: 40px;
  }
  .navbar-brand img {
    max-width: 190px;
    height: auto;
  }
  .project-wrapper {
    align-items: flex-start;
    flex-direction: column;
  }
  .project-wrapper img {
    max-width: 100%;
    height: auto;
  }
  .tab-pane .img-wrap {
    padding: 10px;
  }
  .icon_box img {
    height: 45px !important;
  }
  .left_dv.img-wrapp img {
    width: 100%;
    height: auto;
  }
  .building_zone .details p {
    font-size: 16px;
    line-height: 26px;
  }
  .proj_title {
    font-size: 20px;
  }
  .proj_detail li span {
    font-size: 15px;
  }
  .marketing-list {
    flex-direction: column;
  }
  .good-living-section iframe {
    height: 400px;
  }
  .payment-schedule li p {
    padding: 5px 10px;
    font-size: 14px;
  }
  .tab_ver2.nav-pills .nav-link {
    margin-top: 15px !important;
  }
  .facility-section .facility-wrapper .facility-details-wrap {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 25px 15px;
    left: 0;
  }
  .ul-list li {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .location-section .map-section .location-box {
    position: relative;
    width: 100%;
    top: 0;
    transform: none;
  }
  .residency-wrapper.gallery-wrapper .gallery-box {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .darkform .row > * {
    margin-bottom: 0px;
  }
  .btnheading {
    font-size: 16px;
  }
  .press-release-list {
    margin-left: 0;
    margin-top: 20px;
  }
  .press-release-list li {
    width: 100%;
    margin-left: 0;
  }
  .post_detail ul {
    flex-direction: column;
    align-items: flex-start;
  }
  .project-wrapper img {
    max-width: 100%;
    height: auto;
  }
  .whatsfixed img {
    width: 35px;
    height: 35px;
  }
  .links li {
    font-size: 15px;
  }
  .foo-rera p,
  .whatsapp_box p {
    font-size: 13px;
  }
}
@media only screen and (max-width: 639px) {
  .press-release-list li .press-details {
    width: 100%;
    display: block;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .press-release-list li .press-img {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  html,
  body {
    overflow-x: hidden;
  }
}
