.home_slider .parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}
.home_slider .swiper-slide {
  /* font-size: 18px;
    color: #fff; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100vh;
}
.home_slider .swiper-slide {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  overflow: hidden;
  padding: 50px 0;
}
.home_slider .swiper-slide .slider-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-color: #3c48e3;
  background-blend-mode: multiply;
}
.home_slider .banner-title {
  font-size: 50px;
  color: #11365d;
  display: block;
  padding: 5px;
  text-shadow: 2px 5px 3px #58b;
  font-weight: 800;
}
.home_slider .carousel-caption h3 {
  font-size: 80px;
}
.home_slider .swiper-container {
  width: 100%;
  height: 100%;
  background: #000;
}
.home_slider .swiper-android .swiper-slide,
.home_slider .swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.home_slider .swiper-button-next:after,
.home_slider .swiper-button-prev:after {
  font-size: 18px;
  color: #fff;
}
.home_slider .swiper-button-next,
.home_slider .swiper-button-prev {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 50px;
  position: relative;
  margin: 0 4rem;
  bottom: 100px;
}
.home_slider .swiper-button-next,
.home_slider .swiper-button-prev {
  float: left;
  left: auto;
  right: -72%;
}

section .swiper-button-next,
section .swiper-button-prev,
.swiper-nav button {
  width: 35px;
  height: 35px;
  background: transparent;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid #171c44;
  border-radius: 50px;
}
.swiper-nav button::before {
  color: #171c44;
}
.swiper-nav button:hover {
  background: #171c44;
}
.swiper-nav button:hover::before {
  color: #fff;
}
.swiper-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}
section .swiper-button-next:after,
section .swiper-button-prev:after {
  font-size: 18px;
  color: #fff;
}
.home_slider .cont-btn {
  position: absolute;
  bottom: 0;
  margin-bottom: 4rem;
}
.cust_slider .swiper-pagination {
  position: relative;
}
.cust_slider .swiper-pagination-bullet-active {
  background: #f79433;
}
/* .cust_slider .swiper-button-next,
.cust_slider .swiper-button-prev {
  position: relative;
  margin: 0 7px;
}
.cust_slider .swiper-button-next {
  float: left;
  right: auto;
  right: -45%;
  text-align: left;
}
.cust_slider .swiper-button-prev {
  float: left;
  left: auto;
  right: -45%;
  text-align: left;
} */
.pagination {
  justify-content: center;
  margin-bottom: 0;
  margin-top: 20px;
}

.pagination li {
  background: #ededed;
}

.pagination li a {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222;
}

.pagination li:first-child {
  background: rgba(0, 0, 0, 0);
}

.pagination li:first-child a {
  color: #222;
  font-size: 24px;
}

.pagination li:last-child {
  background: rgba(0, 0, 0, 0);
}

.pagination li:last-child a {
  color: #222;
  font-size: 24px;
}

.pagination li.active {
  background: var(--color-accent-secondary);
}

.pagination li.active a {
  color: #fff;
}

@media only screen and (max-width: 1300px) {
  .home_slider .banner-title {
    font-size: 40px;
  }
  .pno {
    font-size: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .home_slider .swiper-button-next,
  .home_slider .swiper-button-prev {
    right: -67%;
  }
  .home_slider .swiper-button-next,
  .home_slider .swiper-button-prev {
    margin: 0 0.5rem;
    bottom: 44px;
  }
  .home-banner .row {
    height: 55vh !important;
  }
  .home_slider .swiper-slide {
    height: 60vh !important;
  }
}
